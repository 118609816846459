import React, { Suspense, lazy } from 'react'
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";

import Login from './pages/Login';

import { useSelector } from 'react-redux';
import SuperAdmin from './pages/superAdmin/SuperAdmin';
import SuperAdminDashboard from './pages/superAdmin/SuperAdminDashboard';
import SuperAdminCenters from './pages/superAdmin/centers/SuperAdminCenters';
import SuperAdminCenterCreate from './pages/superAdmin/centers/SuperAdminCenterCreate';
import SuperAdminCenterCE from './pages/superAdmin/centers/SuperAdminCenterCE';
import SuperAdmin_admin from './pages/superAdmin/admins/SuperAdmin_admin';
import AdminLogin from './pages/admins/AdminLogin';
import Admins from './pages/admins/Admins';
import AdminDashboard from './pages/admins/AdminDashboard';
import AdminLoginPasswordUpdate from './pages/admins/AdminLoginPasswordUpdate';
import AdminBatch from './pages/admins/batch/AdminBatch';
import AdminCourses from './pages/admins/courses/AdminCourses';
import AdminStudents from './pages/admins/students/AdminStudents';
import AdminStudentCE from './pages/admins/students/AdminStudentCE';
import AdminBatchStudents from './pages/admins/batch/AdminBatchStudents';
import AdminBatchAddStudentToBatch from './pages/admins/batch/AdminBatchAddStudentToBatch';
import AdminCourseMaster from './pages/admins/courses/AdminCourseMaster';
import AdminCourseCategory from './pages/admins/courses/courseCategory/AdminCourseCategory';
import AdminCourseCE from './pages/admins/courses/AdminCourseCE';
import AdminVideos from './pages/admins/videos/AdminVideos';
import AdminVideoCE from './pages/admins/videos/AdminVideoCE';
import AdminVideoVimeo from './pages/admins/videos/vimeo/AdminVideoVimeo';
import AdminVimeoAccounts from './pages/admins/videos/vimeo/adminVimeoAccounts/AdminVimeoAccounts';
import VimeoModules from './pages/admins/videos/videoModules/VimeoModules';
import VideoSubModules from './pages/admins/videos/videoSubModules/VideoSubModules';
import VimeoSubModules from './pages/admins/videos/videoModules/subModules/VimeoSubModules';
import AdminVideoList from './pages/admins/videos/AdminVideoList';
import AdminVideoSubMOduleAdd from './pages/admins/videos/AdminVideoSubMOduleAdd';
import AdminTestSeriesModules from './pages/admins/testSeries/AdminTestSeriesModules';
import AdminTestSubModules from './pages/admins/testSeries/submodules/AdminTestSubModules';
import AdminQuestions from './pages/admins/testSeries/questions/AdminQuestions';
import AdminQuestionCE from './pages/admins/testSeries/questions/AdminQuestionCE';
import AdminLiveClass from './pages/admins/liveClass/AdminLiveClass';
import AdminLiveClassAccount from './pages/admins/liveClass/liveClassAccounts/AdminLiveClassAccount';
import AdminLiveClassSubMod from './pages/admins/liveClass/liveClassSubModule/AdminLiveClassSubMod';
import AdminLiveClassCE from './pages/admins/liveClass/AdminLiveClassCE';
import AdminInstallments from './pages/admins/installments/AdminInstallments';
import AdminExamAlert from './pages/admins/examAlert/AdminExamAlert';
import AdminFeeds from './pages/admins/feeds/AdminFeeds';
import AdminPromotional from './pages/admins/promotional/AdminPromotional';



const TeacherZoomMeeting = lazy(()=>import('./components/zoomMeeting/TeacherZoom'))

function App() {
    const isAdmin =  useSelector((state: any) => state.loginSlice.isSchoolLogin);
    const isInstitte = useSelector((state: any) => state.loginSlice.isInstituteLogin);
  return (
    <BrowserRouter>
      <ToastContainer />
      <Suspense>
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/super-admin" element={isInstitte ? <SuperAdmin /> : <Login/>}>
        <Route path="dashboard" element={<SuperAdminDashboard />} />
        <Route path="centers" element={<SuperAdminCenters />} />
        <Route path="admins" element={<SuperAdmin_admin />} />
        </Route>
        <Route path="/admin/login-password/update" element={<AdminLoginPasswordUpdate />} />
        <Route path="/admin" element={isAdmin ? <Admins /> : <AdminLogin/>}>
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="batches" element={<AdminBatch />} />
        <Route path="batches/students/:id" element={<AdminBatchStudents />} />
        <Route path="batches/students/add/:id" element={<AdminBatchAddStudentToBatch />} />
        <Route path="courses/master" element={<AdminCourseMaster />} />
        <Route path="courses/master/:slug" element={<AdminCourses />} />
        <Route path="courses/master/:slug/category" element={<AdminCourseCategory />} />
        <Route path="courses/master/:slug/category" element={<AdminCourseCategory />} />
        <Route path="courses/master/:slug/create" element={<AdminCourseCE />} />
        <Route path="courses/master/:slug/edit" element={<AdminCourseCE />} />
        <Route path="videos" element={<AdminVideos />} />
        <Route path="videos/create" element={<AdminVideoCE />} />
        <Route path="videos/edit" element={<AdminVideoCE />} />
        <Route path="videos/list/:id" element={<AdminVideoList />} />
        <Route path="videos/submodule/add/:id" element={<AdminVideoSubMOduleAdd />} />
        <Route path="videos/vimeo" element={<AdminVideoVimeo />} />
        <Route path="videos/vimeo/account" element={<AdminVimeoAccounts />} />
        <Route path="videos/modules" element={<VimeoModules />} />
        <Route path="videos/modules/:id" element={<VimeoSubModules />} />
        <Route path="videos/submodules" element={<VideoSubModules />} />
        <Route path="test-series" element={<AdminTestSeriesModules />} />
        <Route path="test-series/submodules/:id" element={<AdminTestSubModules />} />
        <Route path="test-series/nsubmodules/:id/:smod" element={<AdminTestSubModules />} />
        <Route path="test-series/submodules/questions/:id" element={<AdminQuestions />} />
        <Route path="test-series/submodules/questions/create/:id" element={<AdminQuestionCE />} />
        <Route path="test-series/submodules/questions/edit/:id/:quest" element={<AdminQuestionCE />} />
        <Route path="live-class" element={<AdminLiveClass />} />
        <Route path="live-class/account" element={<AdminLiveClassAccount />} />
        <Route path="live-class/submodule" element={<AdminLiveClassSubMod />} />
        <Route path="live-class/join/:meeting" element={<TeacherZoomMeeting />} />
        
        <Route path="students" element={<AdminStudents />} />
        <Route path="students/create" element={<AdminStudentCE />} /> 
        <Route path="students/edit" element={<AdminStudentCE />} />
        <Route path="teachers" element={<AdminDashboard />} />
        <Route path="installments" element={<AdminInstallments />} />
        <Route path="exam-alert" element={<AdminExamAlert />} />
        <Route path="feeds" element={<AdminFeeds />} />
        <Route path="promotions" element={<AdminPromotional />} />
        <Route path="fees-invoice" element={<AdminDashboard />} />
        
        </Route>
      </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
