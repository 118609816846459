import React, { useEffect, useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';

export default function TinyMiceEditor(props:{input?:string,output?:any,heigth?:number}) {
    const [initialValue, setInitialValue] = useState<any>(props.input)
    const [checkState, setCheckState] = useState(false)
    const [value, setValue] = useState<any>(props.input)
    const editorRef:any = useRef(null);
    const handleEditorChange = (newContent:any, editor:any) => {
        props.output(newContent)
        setValue(newContent)
        setCheckState(true)
      };
      useEffect(()=>{
        setInitialValue(props.input)
      },[props.input])
  return (
   
    checkState ? 
    <Editor
    onEditorChange={handleEditorChange}
        apiKey='fk7ckyz9049ltoeac8przeu7vxxpspx47eb1mpjg3fz62exf'
        onInit={(_evt, editor) => editorRef.current = editor}
        value={value}
        init={{
          height: props.heigth ?  props.heigth :500,
          menubar: true,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat| link image | print preview  fullscreen | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;position:relative;z-index:-111;background-color:white }',
        }}
      />
      :
      <Editor
      onEditorChange={handleEditorChange}
          apiKey='fk7ckyz9049ltoeac8przeu7vxxpspx47eb1mpjg3fz62exf'
          onInit={(_evt, editor) => editorRef.current = editor}
          initialValue={initialValue}
         
          init={{
            height: 500,
            menubar: true,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat| link image | print preview  fullscreen | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;position:relative;z-index:-111;background-color:white }',
          }}
        />
   
  )
}
