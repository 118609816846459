import React, { useEffect, useState } from 'react'
import { SuperAdminCreateCenters, SuperAdminEditCenters, SuperAdminShowCenters } from '../../../service/superAdmin/superAdminCenters.service';
import { useForm,SubmitHandler } from 'react-hook-form';
import { SuperAdminCenterTypes } from '../../../types/SuperAdminTypes';
import FormButton from '../../../components/button/FormButton';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';
import { CityService, StateService } from '../../../service/commonService';
import SearchableSelect from '../../../components/form/SearchableSelect';
import RadioButton from '../../../components/form/RadioButton';
import { statusList, toBoolean } from '../../../helpers/helpers';
import SuperAdminCenterCreate from './SuperAdminCenterCreate';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../../redux/contentUpdateSlice';

 
export default function SuperAdminCenterCE(props:{type:string,centerId?:any,open:any}) {
    const [pageLoad, setPageLoad]= useState(false);
    const [fetchData, setFetchData] =useState<any>();
    const [stateList,setStateList]= useState([]);
    const [citiesList,setCitiesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState({
        button:"Create Center",
    })
    
    const dispatch = useDispatch()

    const fetchDetails = async(id:string)=>{
        const get:any = await SuperAdminShowCenters(id)
        if(get?.status === "success"){
            fetchCities(get?.message?.state?.id)
            setFetchData(get.message)
        }

    }    
    const fetchStates = async()=>{
        const get:any = await StateService();
        if(get?.status === "success"){
            let data:any = [];
            get.message.map((item:any)=>{
              data.push({value:item.id,label:item.name})
            })
            setStateList(data)
        }
    }
    const fetchCities = async(stateId:string)=>{
        // console.log(stateId)
        let id:any = stateId;
        if(typeof(id) === "object"){
            id = id.value;
        }

        const get:any = await CityService(id);
        if(get?.status === "success"){
            let data:any = [];
            get.message.map((item:any)=>{
              data.push({value:item.id,label:item.name})
            })
            setCitiesList(data)
        }
    }
    useEffect(()=>{ 

        setPageLoad(true)
        if(props.type === "edit"){
            fetchDetails(props.centerId.id)
            setPageContent({
                button:"Update Center"
            })
        }
        fetchStates()
        setPageLoad(false)
    },[])

    const { handleSubmit, control } = useForm<SuperAdminCenterTypes>({
        values:{
            name:fetchData?.name,
            fkstateId:fetchData?.state?.id,
            fkcityId:fetchData?.city?.id,
            status:`${fetchData?.status}`
        }
    });
    const onSubmit: SubmitHandler<SuperAdminCenterTypes> = async(data) => {
        setLoading(true);
        let status_:any =  toBoolean(data.status);
        let formdata:any = {
            name:data.name,
            fkstateId:data.fkstateId?.value,
            fkcityId:data.fkcityId?.value,
            status:status_
        }
        let fetch:any;
        console.log(formdata);
       if(props.type==="create"){
        fetch = await SuperAdminCreateCenters(formdata);
       }
       if(props.type === "edit"){
        fetch = await SuperAdminEditCenters(fetchData?.id,formdata);
       }
       if(fetch?.status=== "success"){
        setLoading(false)
        dispatch(setContentUpdate({
            id:Math.random() * (100000 - 111111)
          }))
          props.open(false)
        return toast.success(fetch.message);
    }
    setLoading(false)
      
      };    
    
  return (
    <>
    <div className="my-2">
    <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
    <Input 
       name="name"
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="Center Name"
       placeholder="Center Name"
      />
      <SearchableSelect
        name="fkstateId"
        
        control={control}
        options={stateList}
        rules={formValidationRules.required()}
        label="Select State"
        placeholder="Search.."
        onFetch={fetchCities}
      />
       <SearchableSelect
        name="fkcityId"
        control={control}
        options={citiesList}
        rules={formValidationRules.required()}
        label="Select City"
        placeholder="Search.."
      />
       <RadioButton
        name="status"
        control={control}
        rules={formValidationRules.required('Please select status')}
        options={statusList}
        label="status"
      />
        <br/>
        <FormButton loading={loading} title={pageContent.button} />

    </form>
    </div>
    </>
  )
}
