import { configureStore } from '@reduxjs/toolkit';
import breadcrumSlice from './breadcrumSlice';
import commonSlice from './commonSlice';
import mobileSideBar from './mobileSideBar';
import themeSlice from './themeSlice';
import loginSlice from './loginSlice';
import courseUpdateSlice from './courseUpdateSlice';
import contentUpdateSlice from './contentUpdateSlice';

const store = configureStore({
    reducer: {
        breadcrumSlice: breadcrumSlice,
        commonSlice:commonSlice,
        mobileSideBar:mobileSideBar,
        themeSlice:themeSlice,
        loginSlice:loginSlice,
        courseUpdateSlice:courseUpdateSlice,
        contentUpdateSlice:contentUpdateSlice
    },
});

export default store;