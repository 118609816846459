import React from 'react'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid'

export default function AdminInstallments() {
  return (
    <>
    <BreadcrumbBack title={`Installments`}>
   </BreadcrumbBack>

    <div className="my-2">
      <PaginationDataGrid  name="adminInstallments" /> 
    </div>
    </>
  )
}
