import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AdminVideosTypes } from "../../../types/AdminTypes";
import { statusList, toBoolean } from "../../../helpers/helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BreadcrumbBack from "../../../components/breadcrumb/BreadcrumbBack";
import Input from "../../../components/form/Input";
import formValidationRules from "../../../helpers/formvalidationRules";
import SearchableSelect from "../../../components/form/SearchableSelect";
import DropZoneUploadImages from "../../../components/dropzone/DropZoneUploadImages";
import TinyMiceEditor from "../../../editor/TinyMiceEditor";
import { createAdminVideos, editAdminVideos, getVideosTypes, showAdminVideos } from "../../../service/admin/adminVideoes.service";
import FormButton from "../../../components/button/FormButton";

export default function AdminVideoCE() {
  const [videoTypeList, setVideoTypeList] = useState<any>([]);
  const [videoDetail, setVideoDetail] = useState<any>();
  const [url, setUrl] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState({
    button: "Create Video",
    title: "Create Video",
  });
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] = useState<any>(false);
  const [initalEditor, setInitalEditor] = useState<any>(undefined);
  const location = useLocation();
  const locationLength = location.pathname.split("/").length;
  const params = new URLSearchParams(location.search);
  let pageType = location.pathname.split("/")[locationLength - 1];
  const navigate = useNavigate()
  const { handleSubmit, control } = useForm<AdminVideosTypes>({
    values:{
      name:videoDetail?.name,
      fk_videoType_id:videoDetail?.videoType?.id,
      description:initalEditor,
      status:videoDetail?.status ? 'true' : 'false',
      thumbnail:videoDetail?.thumbnail,
      length:videoDetail?.length,
      watchtime:videoDetail?.watchtime,
      url:videoDetail?.url,
    }
  });

  const fetchVideoTypes = async()=>{
    const get:any = await getVideosTypes();
    if(get?.status === "success"){
      let data:any = [];
      get.message.map((item:any)=>{
        data.push({value:item.id,label:item.name})
      })
      setVideoTypeList(data)
    }
  }
  const fetchVidesDetails = async(id:string)=>{
    const get:any = await showAdminVideos(id)
    if(get?.status === "success"){
      setVideoDetail(get.message)
      setUrl(get.message.thumbnail)
      setInitalEditor(get.message.description)
    }
  }
  useEffect(()=>{
      fetchVideoTypes();
      if(pageType === "edit"){
        let id:any = params.get('id');
        fetchVidesDetails(id)
        setPageContent({
          button: "Edit Video",
          title: "Edit Video",
        })
      }
  },[])

  const onSubmit: SubmitHandler<AdminVideosTypes> = async (data: AdminVideosTypes) => {
    setLoading(true);
    let status_: any = toBoolean(data.status.value);
    let fetch: any;
    data.status = status_;
    data['thumbnail'] = url;
    data['description'] = initalEditor
    let videoTypeId:any = data?.fk_videoType_id?.value;
    if(videoTypeId  === undefined) {
      videoTypeId = videoDetail?.videoType?.id
    }
     data.fk_videoType_id = videoTypeId
       if(pageType==="create"){
        fetch = await createAdminVideos(data);
       }
       if(pageType === "edit"){
        let id:any = params.get('id');
        fetch = await editAdminVideos(id,data);
       }
    if (fetch?.status === "success") {
      setLoading(false);
      navigate('/admin/videos')
      return toast.success(fetch.message);
    }
    
    setLoading(false);
  };

  const handleEditor = (output:any)=>{
    setInitalEditor(output)
 }

  return (
    <>
      <BreadcrumbBack title={pageContent.title}></BreadcrumbBack>
      <div className="my-2">
        <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="name"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                  ...formValidationRules.minLength(3),
                }}
                label="Title"
                placeholder="title"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <SearchableSelect
                name="fk_videoType_id"
                control={control}
                options={videoTypeList}
                rules={formValidationRules.required()}
                label="Select Video Type"
                placeholder="select video type"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="url"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                  ...formValidationRules.minLength(3),
                }}
                label="Video URL"
                placeholder="url"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="length"
                control={control}
                type="number"
                rules={{
                  ...formValidationRules.required(),
                  ...formValidationRules.minLength(3),
                }}
                label="Duration"
                placeholder="duration"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="watchtime"
                control={control}
                type="number"
                rules={{
                  ...formValidationRules.required(),
                }}
                label="Restriction (in times)"
                placeholder="restriction"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
            <SearchableSelect
                name="status"
                control={control}
                options={statusList}
                rules={formValidationRules.required()}
                label="Select Status"
                placeholder="select Status"
              />
            </div>
          </div>
          <div className="my-2">
      <DropZoneUploadImages heigth='min-h-[25vh]' title="Upload Course Master Image" isRequired={true} maxFile={1} data={setUrl} url={url} status={setCheckUploadedFilesStatus} />
      </div>
        <TinyMiceEditor input={initalEditor} output={handleEditor} />
        <div className="my-2">
        <FormButton loading={loading} title={pageContent.button} />
        </div>
        </form>
      </div>
    </>
  );
}
