import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../components/button/CustomButton';
import Models from '../../../../components/models/Models';
import AdminCourseCategoryCE from './AdminCourseCategoryCE';
import { slugAdminCourseMaster } from '../../../../service/admin/adminCourses.service';
import { capitalizeString } from '../../../../helpers/helpers';
import PaginationDataGrid from '../../../../components/tables/PaginationDataGrid';

export default function AdminCourseCategory() {
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [slugData, setSlugData] =useState<any>();
    let pathlength = location.pathname.split('/').length
    let coursMasterName = location.pathname.split('/')[pathlength-2]
    const fetchSlugData = async(slug:string)=>{
        const get =  await slugAdminCourseMaster(slug)
        if(get?.status === "success"){
            setSlugData(get.message)

        }
    }
    useEffect(() =>{
        fetchSlugData(coursMasterName)
    },[]) 
  return (
    <>
    <BreadcrumbBack title={`${capitalizeString(slugData?.name)}'s Course Category`}>
    <CustomButton onClick={(()=>setOpen(!open))}  title='Create New' icon={true} iconName='create'    />
    </BreadcrumbBack>
    <div className="my-2">
        {
            slugData?.id ? 
            <PaginationDataGrid name="adminCoursecategory" id={slugData?.id} />
            :null
        }
     
    </div>
    <Models name='Create New Center' isOpen={open} isClose={setOpen}  >
        <AdminCourseCategoryCE type='create' open={setOpen} data={{id:"",master:slugData?.id}} />
    </Models>
    </>
  )
}
