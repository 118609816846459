import moment from "moment";

export const  generateRandomString = (length:number,number?:boolean) =>{
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    if(number){
      characters = '1234567890';
    }
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
}

export const getDateStatus  = (date:any, daysAgo:number)=>{
    const today:any = new Date();
    const compareDate:any = new Date(date);
  
    // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison
    today.setHours(0, 0, 0, 0);
    compareDate.setHours(0, 0, 0, 0);
  
    // Calculate the difference in milliseconds
    const difference = today - compareDate;
    const oneDay = 24 * 60 * 60 * 1000;
  
    if (difference < oneDay) {
      return 'Today';
    } else if (difference < oneDay * 2) {
      return 'Yesterday';
    } else if (difference <= oneDay * daysAgo) {
      return `${daysAgo} days ago`;
    } else {
      return moment(date).format("YYYY-MM-DD");
    }
}