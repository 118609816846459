import { adminTestSeriesSubModulesTypes, AdminTypes } from "../../types/AdminTypes";
import instance from "../instace";

export const    createAdminTestSeriesModules = async(name:string,status:boolean,attempt?:number)=>{
    try{
        const get:any = await instance.post('/api/admin/test-series/module/create',{
            name,
            status,
            attempt
        })
        return get.data;
    }catch(e){
        return false;
    }
}

export const getAdminTestSeriesModules = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/test-series/module/get?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAdminTestSeriesModules  = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/test-series/module/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminTestSeriesModules = async(id:string,name:string,status:boolean,attempt?:number)=>{
    try{

     const get:any = await instance.put(`/api/admin/test-series/module/edit/${id}`,{
            name,
            status,
            attempt
     })
        return get.data;
    }catch(e){
        return false;
    }
}
export const updateStatusAdminTestSeriesModules = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/test-series/module/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminTestSeriesModules = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/test-series/module/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}





export const    createAdminTestSeriesSubModules = async(data:adminTestSeriesSubModulesTypes)=>{
    try{
        const get:any = await instance.post('/api/admin/test-series/module/submodule/create',{
            fk_testModule_id:data.fk_testModule_id,
            name:data.name,
            status:data.status,
            attempt:data.attempt,
            fk_dir_id:data.fk_dir_id,
            marks:data.marks,
            negative_marks:data.negative_marks,
            duration:data.duration,
            fk_nmod_id:data.fk_nmod_id
           
        })
        return get.data;
    }catch(e){
        return false;
    }
}

export const getAdminTestSeriesSubModules = async(moduleId:string,page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/test-series/module/submodule/get/${moduleId}?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAdminTestSeriesSubModules  = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/test-series/module/submodule/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminTestSeriesSubModules = async(id:string,data:adminTestSeriesSubModulesTypes)=>{
    try{

     const get:any = await instance.put(`/api/admin/test-series/module/submodule/edit/${id}`,{
        fk_testModule_id:data.fk_testModule_id,
        name:data.name,
        status:data.status,
        attempt:data.attempt,
        marks:data.marks,
        negative_marks:data.negative_marks,
        duration:data.duration,
        fk_dir_id:data.fk_dir_id,
        fk_nmod_id:data.fk_nmod_id
     })
        return get.data;
    }catch(e){
        return false;
    }
}
export const updateStatusAdminTestSeriesSubModules = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/test-series/module/submodule/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminTestSeriesSubModules = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/test-series/module/submodule/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const getAdminTestSeriesNSubModules = async(moduleId:string,page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/test-series/module/submodule/get/n-submodules/${moduleId}?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}







export const    createAdminTestSeriesQuestion = async(data:any)=>{
    try{
        const get:any = await instance.post('/api/admin/test-series/questions/create',{
            fk_testType_id:data.fk_testType_id,
            fk_testSubModules_id:data.fk_testSubModules_id,
            chapter:data.chapter,
            question:data.question,
            answer:data.answer,
            subject:data.subject,
            description:data.description,
            video_link:data.video_link,
            explaination:data.explaination,
            status:data.status,
            option:data.option
           
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const getSubModuleQuestionDetails = async(submoduleId:string,page?:number,query?:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/test-series/module/submodule/questions/details/${submoduleId}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getTestTypes = async()=>{
    try{
        const get:any = await instance.get(`/api/admin/test-series/test-type`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminTestSeriesSubQuestion = async(submoduleId:string,page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/test-series/questions/get/${submoduleId}?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAdminTestSeriesSubQuestion = async(id:string)=>{
    try{
       
        const get:any = await instance.get(`/api/admin/test-series/questions/show/${id}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const    editAdminTestSeriesQuestion = async(id:string,data:any)=>{
    try{
        const get:any = await instance.put(`/api/admin/test-series/questions/edit/${id}`,{
            fk_testType_id:data.fk_testType_id,
            fk_testSubModules_id:data.fk_testSubModules_id,
            chapter:data.chapter,
            question:data.question,
            answer:data.answer,
            subject:data.subject,
            description:data.description,
            video_link:data.video_link,
            explaination:data.explaination,
            status:data.status,
            option:data.option
           
        })
        return get.data;
    }catch(e){
        return false;
    }
}

export const    updateStatusAdminTestSeriesQuestion = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/test-series/questions/status/${id}`,{
            status:status
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const    deleteAdminTestSeriesQuestion = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/test-series/questions/delete/${id}`)
        return get.data;
    }catch(e){
        return false;
    }
}


export const getAllTestSeriesModules = async()=>{
    try{
       
        const get:any = await instance.get(`/api/admin/test-series/module/get-all`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAllTestSeriesSubModules = async(id:string)=>{
    try{
       
        const get:any = await instance.get(`/api/admin/test-series/submodules/get-all/${id}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAllTestModulesByCourse = async(id:string)=>{
    try{
       
        const get:any = await instance.get(`/api/admin/test-series/relation/course/${id}`)
        return get.data;
    }catch(e){
        return false;
    }
}

export const createTestSeriesRelationByCourse  = async(data:any)=>{
    try{
        const get:any = await instance.post(`/api/admin/test-series/relation/create`,{
            rel:data
        });
        return get.data;
    }catch(e){
            return false;
    }
}
