import { SuperAdminCenterTypes } from "../../types/SuperAdminTypes";
import instance from "../instace"

export const SuperAdminAllCenters = async()=>{
    try{
        const get:any = await instance.get('/api/institute/center');
        return get.data;
    }catch(e){
            return false;
    }
}
export const ActiveInstituteCenters = async()=>{
    try{
        const get:any = await instance.get('/api/institute/center/get/active');
        return get.data;
    }catch(e){
            return false;
    }
}

export const SuperAdminCreateCenters = async(superAdminType:SuperAdminCenterTypes)=>{
    try{
        const get:any = await instance.post('/api/institute/center',{
            name:superAdminType.name,
            fkstateId:superAdminType.fkstateId,
            fkcityId:superAdminType.fkcityId,
            status:superAdminType.status,
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const SuperAdminShowCenters = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/institute/center/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const SuperAdminEditCenters = async(id:string,superAdminType:SuperAdminCenterTypes)=>{
    try{
        const get:any = await instance.put(`/api/institute/center/${id}`,{
            name:superAdminType.name,
            fkstateId:superAdminType.fkstateId,
            fkcityId:superAdminType.fkcityId,
            status:superAdminType.status,
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const SuperAdminStatusCenters = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/institute/center/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const SuperAdminDeleteCenters = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/institute/center/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
