import React from 'react'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../components/button/CustomButton'
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid'
import { useNavigate } from 'react-router-dom'

export default function AdminVideos() {

    const navigate = useNavigate()

  return (
    <>
       <BreadcrumbBack title="Videos">

        <CustomButton
          title="Manage Vimeo "
          icon={true}
          onClick={(()=>navigate('/admin/videos/vimeo'))}
          bg='bg-blue-500'
          iconName="vimeo"
        />
                <CustomButton
                  onClick={(()=>navigate(`/admin/videos/modules`))}
          title="Manage Modules"
          icon={true}
          bg="bg-purple-900"
          iconName="folderTree"
        />
                <CustomButton
                onClick={(()=>navigate(`/admin/videos/create`))}
          title="Create New Video"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>
      <div className="my-2">
        <PaginationDataGrid name="adminVideos" />
      </div>
    </>
  )
}
