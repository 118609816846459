import { useLocation } from "react-router-dom";

export const randomNumber=(max:number)=>{
    return Math.floor(Math.random() * max);
}

export const statusList  = [
    {value:'true',label:"Active"},
    {value:'false',label:"Inactive"},
]
export const toBoolean = (value:any) =>{
    if(value === "true"){
        return true
    }
    return false 
}
export const capitalizeString = (str:string)=> {
    if (!str) return '';
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  export const SearchURLParams = (key:string)=>{
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    return params.get(key);
  }

  export const getpagingNumber = (range:number,start:number,last:number)=>{
    const result = [];
    const starts = Math.max(start - range, 1);
    const end = Math.min(start +range, last);
   
    for (let i = starts; i <= end; i++) {
      result.push(i);
    }
  
  
      return result;

  }
  export const truncateString = (str:string, maxLength:number) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + '...';
  };