import {  AdminVimeoAccountTypes } from "../../types/AdminTypes";
import instance from "../instace";


export const createAdminVimoeAccount = async(data:AdminVimeoAccountTypes)=>{
    try{
     const get:any = await instance.post(`/api/admin/vimeo/create`,{
        name:data.name,
        token:data.token,
        identifier:data.identifier,
        secret:data.secret
     })
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminVimoeAccount = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/vimeo/get?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAdminVimoeAccount  = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/vimeo/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminVimoeAccount = async(id:string,data:AdminVimeoAccountTypes)=>{
    try{

     const get:any = await instance.put(`/api/admin/vimeo/edit/${id}`,{
        name:data.name,
        token:data.token,
        identifier:data.identifier,
        secret:data.secret
     })
        return get.data;
    }catch(e){
        return false;
    }
}
export const updateStatusAdminVimoeAccount = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/vimeo/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminVimoeAccount = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/vimeo/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}