import { Spinner } from '@material-tailwind/react';
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
export default function YoutubePlayer(props:{url:string}) {
    const [isReady, setIsReady] = useState(false);
    const [isBuffering, setIsBuffering] = useState(true);
    const handleReady = () => {
        setIsBuffering(false);
        setIsReady(true);
      };
    
      const handleBuffer = () => {
        setIsBuffering(true);
      };
  return (
    <div className='flex flex-col items-center  justify-center mb-8 '>
{!isReady && <Spinner/>}
      <div style={{ display: isReady ? 'block' : 'none' }}>
        <ReactPlayer
          url={props.url}
          onReady={handleReady}
          controls
          width={"100%"}
          height={"250px"}
          playing
        />
      </div>
</div>
  )
}
