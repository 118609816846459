

export const CSVDownload = (name:string,data:any)=>{
   const headers = Object.keys(data[0]).join(',');
  const csvContent = `${headers}\n${data.map((row:any) => Object.values(row).join(',')).join('\n')}`;

  // Create a Blob with text content
  const blob = new Blob([csvContent], { type: 'text/csv' });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element and trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.csv`);
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
}