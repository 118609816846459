import React, { useState } from 'react'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../components/button/CustomButton'
import AdminCourseMasterCE from './AdminCourseMasterCE'
import Models from '../../../components/models/Models'
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid'

export default function AdminCourseMaster() {
  const [open, setOpen] = useState(false)
  
  return (
    <>
    <BreadcrumbBack title="Course Master">
    <CustomButton onClick={(()=>setOpen(!open))}  title='Create Course Master' icon={true} iconName='create'    />
    </BreadcrumbBack>
    <div className='my-2'>
        <PaginationDataGrid name="AdminCourseMaster" />
    </div>

    <Models name='Create New Course Master' isOpen={open} isClose={setOpen}  >
        <AdminCourseMasterCE type='create' open={setOpen} />
    </Models>
    </>
  )
}
