import React, { useEffect, useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';
export default function TinyMiceLiteEditor(props:{input?:string,output?:any,heigth?:number}) {
    const [initialValue, setInitialValue] = useState<any>(props.input)
    const [checkState, setCheckState] = useState(false)
    const [value, setValue] = useState<any>(props.input)
    const editorRef:any = useRef(null);
    const handleEditorChange = (newContent:any, editor:any) => {
        props.output(newContent)
        setValue(newContent)
        setCheckState(true)
      };
      useEffect(()=>{
        setInitialValue(props.input)
      },[props.input])
  return (
   
    checkState ? 
    <Editor
    onEditorChange={handleEditorChange}
    
        apiKey='fk7ckyz9049ltoeac8przeu7vxxpspx47eb1mpjg3fz62exf'
        onInit={(_evt, editor) => editorRef.current = editor}
        value={value}
        init={{
          height: props.heigth ? props.heigth : 300,
          menubar: false,
          plugins: [
            'autolink', 'codesample', 'link', 'lists',
            'media', 'powerpaste', 'table', 'image',
            'quickbars', 'codesample', 'help'
          ],
          toolbar: true,
          quickbars_insert_toolbar: 'quicktable image media codesample',
          quickbars_selection_toolbar: 'bold italic underline | blocks | blockquote quicklink',
          contextmenu: 'undo redo | inserttable | cell row column deletetable | help',
          powerpaste_word_import: 'clean',
          powerpaste_html_import: 'clean',
        }}
      />
      :
      <Editor
     
         onEditorChange={handleEditorChange}
         
          apiKey='fk7ckyz9049ltoeac8przeu7vxxpspx47eb1mpjg3fz62exf'
          onInit={(_evt, editor) => editorRef.current = editor}
          initialValue={initialValue}
        
          init={{
            height: props.heigth ? props.heigth : 300,
            menubar: false,
            plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
            toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | image media'
           
           
          }}
        />
   
  )
}
