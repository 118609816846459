import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { statusList, toBoolean } from "../../../../helpers/helpers";
import { useDispatch } from "react-redux";
import { setContentUpdate } from "../../../../redux/contentUpdateSlice";
import { toast } from "react-toastify";
import Input from "../../../../components/form/Input";
import formValidationRules from "../../../../helpers/formvalidationRules";
import RadioButton from "../../../../components/form/RadioButton";
import FormButton from "../../../../components/button/FormButton";
import { adminLiveClassSubTypes } from "../../../../types/AdminTypes";
import { createAdminLiveClassSubmodule, editAdminLiveClassSubmodule, showAdminLiveClassSubmodule } from "../../../../service/admin/adminLiveClass.service";

export default function AdminLiveClassSubModCE(props: {
  type: string;
  data?: any;
  open: any;
}) {
    const [pageLoad, setPageLoad]= useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [url, setUrl] = useState<any>();
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] = useState<any>(false);
  const [pageContent, setPageContent] = useState({
    button:"Create Live Class Submodule",
})

    
    const fetchBatchDetail = async(id:string)=>{
        const get:any = await showAdminLiveClassSubmodule(id)
      if(get?.status === "success"){
        setUrl(get.message?.image)
        setFetchData(get.message)
      }
    }
    useEffect(()=>{ 

        setPageLoad(true)
        if(props.type === "edit"){
            fetchBatchDetail(props.data.id)
            setPageContent({
                button:"Update Live Class Submodule"
            })
        }
        // fetchInstituteCenter()
        setPageLoad(false)
      },[])

  const { handleSubmit, control } = useForm<adminLiveClassSubTypes>({
    values: {
      name: fetchData?.name,
      status: `${fetchData?.status}`,
    },
  });
  const onSubmit: SubmitHandler<adminLiveClassSubTypes> = async (data:adminLiveClassSubTypes) => {
    setLoading(true);
    let fetch:any;
    let status_: any = toBoolean(data.status);
       if(props.type==="create"){
        fetch = await createAdminLiveClassSubmodule(data.name,status_);
       }
       if(props.type === "edit"){
        fetch = await editAdminLiveClassSubmodule(fetchData?.id,data.name,status_);
       }
    if (fetch?.status === "success") {
      setLoading(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      props.open(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };

  
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <Input
          name="name"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Name"
          placeholder="Name"
        />
      <div className="my-3">
       <RadioButton
        name="status"
        control={control}
        rules={{...formValidationRules.required()}}
        options={statusList}
        label="status"
      />
       </div>
       <br/>
       <FormButton loading={loading} title={pageContent.button} />
      </form>
    </>
  );
}
