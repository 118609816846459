import React, { useEffect, useState } from 'react'
import { createVideoRelationByCourse, getAllVideoModules, getAllVideoModulesSubModules, getAllVideoonlySubmodules, getAllVideoRelationByCourse } from '../../../../service/admin/adminVideoes.service';
import MultiSearchableSelect from '../../../../components/form/MultiSearchableSelect';
import { SubmitHandler, useForm } from 'react-hook-form';
import formValidationRules from '../../../../helpers/formvalidationRules';
import FormButton from '../../../../components/button/FormButton';
import { toast } from 'react-toastify';
import { createTestSeriesRelationByCourse, getAllTestModulesByCourse, getAllTestSeriesModules, getAllTestSeriesSubModules } from '../../../../service/admin/adminTestSeries.service';
import { createLiveClassRelationByCourse, getAllLiveClassRelationByCourse, getAllLiveClassSubmodules } from '../../../../service/admin/adminLiveClass.service';
export default function AdminCourseLiveClassRelation(props:{id:string}) {

    const [fetchAllList, setFetchList] = useState([]);
    const [selectedModules, setSelectedModules] = useState([])
    const  [loading,setLoading] = useState(false);

    const fetchModuleData = async()=>{
        let get:any = await getAllLiveClassSubmodules();
        if(get?.status==="success"){
            let data:any = [];
            get.message.map((item:any)=>{
              data.push({value:item.id,label:item.name})
            })
            setFetchList(data)
          }
    }

      const getSelectedData = async(id:string)=>{
        let get:any = await getAllLiveClassRelationByCourse(id)
        if(get?.status === "success"){
          setSelectedModules(get?.message.map((item:any) => item.fk_liveClassSubmodule_id))
        }
      }
    useEffect(()=>{
        fetchModuleData();
        getSelectedData(props.id);
    },[props.id])

    const { handleSubmit, control } = useForm<any>({
      values:{
        modules : selectedModules ? selectedModules : [],
      }
    });
  
    const onSubmit: SubmitHandler<any> = async(data:any) => {
      setLoading(true);
      let sendingData:any = [];
      if(data.modules.length > 0){
        data.modules.map((e:any) => {
          sendingData.push({
            fk_course_id: props.id,
            fk_liveClassSubmodule_id:e,
          })
          
        })
      }
      let cr =  await createLiveClassRelationByCourse(sendingData)
      if(cr?.status === 'success'){
        toast.success("live class course relation successfully created");
       
      }
    setLoading(false)
    
    };  
    
  return (
    <>
     <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
                <div className='mb-[1rem]'>
                <MultiSearchableSelect
                name="modules"
                control={control}
                options={fetchAllList}
                label="Select Module"
                placeholder="Search.." 
                />
                <FormButton loading={loading} title={`Submit`} />
                </div>
                
                </form>
    </>
    

  )
}
