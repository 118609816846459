import React, { useEffect, useState } from 'react'
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../../components/button/CustomButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { getSubModuleQuestionDetails } from '../../../../service/admin/adminTestSeries.service'
import Loaders from '../../../../components/loaders/Loaders'
import PaginationDataGrid from '../../../../components/tables/PaginationDataGrid'
import Models from '../../../../components/models/Models'
import WordUpload from '../../../../components/uploads/WordUpload'
import TableButton from '../../../../components/button/TableButton'

export default function AdminQuestions() {
    const [open, setOpen] =  useState(false)
    const [subModuleDetails, setSubModuleDetails] = useState<any>();
    const [pageLoading, setPageLading] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const subModuleId = location.pathname.split('/')[location.pathname.split('/').length-1] 
    const fetchSubModuleDetails =async(id:string)=>{
    
        let get:any = await getSubModuleQuestionDetails(id)
        if(get?.status === "success"){
            setSubModuleDetails(get?.message)
        }
    }
    useEffect(()=>{
        setPageLading(true)
        fetchSubModuleDetails(subModuleId)
        setPageLading(false)
    },[])
    const handleFileUpload = ()=>{
      setOpen(!open)
    }
  return (
    <>
    {
        pageLoading === true ? 
            <Loaders type='spinning' />
        
            :
            <>
            <BreadcrumbBack title={`${subModuleDetails?.name}'s Questions`}>
            <TableButton  onClick={handleFileUpload} text='Upload Question' icon={true} title='upload' toottip='Upload Word'  />
             <CustomButton
             onClick={(()=>navigate(`/admin/test-series/submodules/questions/create/${subModuleId}?name=${subModuleDetails?.name}`))}
       title="Create Questions"
       icon={true}
       iconName="create"
     />
      
   </BreadcrumbBack>
   <div className="my-2 p-3 rounded-xl bg-gray-200">
   
    <div className="flex flex-row flex-wrap justify-between gap-3 my-1">
    <p>Total Questions: {subModuleDetails?.questions}</p>
    <p>Duration: {subModuleDetails?.duration}</p>
    <p>Attempts: {subModuleDetails?.attempt}</p>
    <p>Marks: {subModuleDetails?.marks}</p>
    <p>Negative Marks: {subModuleDetails?.negative_marks}</p>
    </div>
   </div>
   <div className="my-2">
     <PaginationDataGrid name="adminTestSeriesQuestion" id={subModuleId} />
   </div>

   <Models name='Upload Questions' isOpen={open}  isClose={setOpen} >
            <WordUpload name='testSeriesQuestion' data={subModuleId} accept='.doc, .docx'   open={setOpen} />
        </Models>
   </>
    }
 </>
  )
}
