import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getActiveGendersService } from '../../../service/commonService';
import { createAdminStudent, editAdminStudent, showAdminStudent } from '../../../service/admin/adminStudent.service';
import Swal from 'sweetalert2';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import { AdminStudentTypes } from '../../../types/AdminTypes';
import { SubmitHandler, useForm } from 'react-hook-form';
import { statusList, toBoolean } from '../../../helpers/helpers';
import { toast } from 'react-toastify';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';
import RadioButton from '../../../components/form/RadioButton';
import FormButton from '../../../components/button/FormButton';
import SearchableSelect from '../../../components/form/SearchableSelect';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../../redux/contentUpdateSlice';

export default function AdminStudentCE(props:{type?: string,data?:any,isOpen?:any}) {
    const location = useLocation();
    const navigate = useNavigate()
    const [pageLoad, setPageLoad]= useState(false);
    const [genderData, setGenderData]= useState([]);
    const [fetchData, setFetchData] =useState<any>();
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState({
        button:"Create New Student",
        title:"Create Student"
    })
    const dispatch = useDispatch()
    const fetchGender = async()=>{
        const get:any = await getActiveGendersService();
            if(get?.status === "success"){
                let data:any = [];
          get.message.map((item:any)=>{
            data.push({value:item.id,label:item.name})
          })
          setGenderData(data)
              }
    }
    const fetchDetail = async(id:string)=>{
        const get:any = await showAdminStudent(id);
        if(get?.status === "success"){
            setFetchData(get.message)
          }
    }
    useEffect(()=>{
        if(props.type === "edit"){
            setPageContent({
                button:"Edit Student",
                title:"Edit Student"
            })
            if(props.data.id){
                fetchDetail(props.data.id)
            }
        }
        fetchGender()
    },[])

    const { handleSubmit, control } = useForm<AdminStudentTypes>({
        values:{
            name:fetchData?.name,
            email:fetchData?.email,
            phone:fetchData?.phone,
            dob:moment(fetchData?.dob,'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD"),
            gender_id:fetchData?.gender?.id,
            status:`${fetchData?.status}`,
        }
      });

      const onSubmit: SubmitHandler<AdminStudentTypes> = async(data:any) => {
        setLoading(true);
        let status_:any =  toBoolean(data.status);
        let fetch:any;
        data.status = status_;
        data.gender_id = data.gender_id.value
       if(props.type==="create"){
        fetch = await createAdminStudent(data);
       }
       if(props.type === "edit"){
        fetch = await editAdminStudent(props.data.id,data);
       }
       if(fetch?.status=== "success"){
        dispatch(setContentUpdate({
          id:Math.random() * (100000 - 111111)
        }))
        setLoading(false)
        props.isOpen(false)
        return toast.success(fetch.message);
      }
      setLoading(false)
      
      };  

  return (
    <>
      <div className="">
      <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
    <Input 
       name="name"
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="Name"
       placeholder="Name"
      />
      <div className="grid grid-cols-12 gap-2">
            <div className=' col-span-12 sm:col-sapn-12 md:col-span-12 lg:col-span-6 '>
            <Input 
       name="email"
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="email"
       type='email'
       placeholder="email"
      />
            </div>
            <div className=' col-span-12 sm:col-sapn-12 md:col-span-12 lg:col-span-6 '>
            <Input 
       name="phone"
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="Phone"
       placeholder="Phone"
      />
            </div>
      </div>
      <div className="grid grid-cols-12 gap-2">
            <div className=' col-span-12 sm:col-sapn-12 md:col-span-12 lg:col-span-6 '>
            <Input 
            type='date'
       name="dob"
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="date of birth"
       placeholder="date of birth"
      />
            </div>
            <div className=' col-span-12 sm:col-sapn-12 md:col-span-12 lg:col-span-6 '>
            <SearchableSelect
        name="gender_id"
        control={control}
        options={genderData}
        rules={formValidationRules.required()}
        label="Select gender"
        placeholder="Search.."
      />
            </div>
      </div>

       <RadioButton
        name="status"
        control={control}
        rules={{...formValidationRules.required()}}
        options={statusList}
        label="status"
      />
        <br/>
        <FormButton loading={loading} title={pageContent.button} />

    </form>
        </div>
    </>
   
  )
}
