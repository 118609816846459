import React from 'react'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../components/button/CustomButton'
import { useLocation, useNavigate } from 'react-router-dom';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';

export default function AdminVideoList() {
    const location = useLocation();
    let moduleId = location.pathname.split('/')[location.pathname.split('/').length - 1];
    let moduleName = new URLSearchParams(location.search)
    const navigate = useNavigate();
  return (
    <>
     <BreadcrumbBack title={`${(moduleName.get("name"))}'s videos`}>
        <CustomButton
            onClick={(()=>navigate(`/admin/videos/submodule/add/${moduleId}?name=${(moduleName.get("name"))}&mod=${(moduleName.get("mod"))}`))}
          title="Add More Video"
          icon={true}
          iconName="create"
        />
    </BreadcrumbBack>
    <div className="my-2">
        <PaginationDataGrid name="adminSubModulesVideoList" dataId={moduleId} />
    </div>
    </>
  )
}
