import React, { useState } from 'react'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../components/button/CustomButton'
import Models from '../../../components/models/Models'
import AdminCourseCategory from './courseCategory/AdminCourseCategory'
import { useLocation, useNavigate } from 'react-router-dom'
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid'

export default function AdminCourses() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  let pathlength = location.pathname.split('/').length
  let coursMasterName = location.pathname.split('/')[pathlength-1]

  return (
    <>
    <BreadcrumbBack title={`${coursMasterName}'s Courses`}>
    <CustomButton onClick={(()=>navigate(`/admin/courses/master/${coursMasterName}/create`))} bg='bg-purple-800'  title='Create Course' icon={true} iconName='create'    />
    <CustomButton onClick={(()=>navigate(`${location.pathname}/category`))}  title='Create Course Category' icon={true} iconName='create'    />
    </BreadcrumbBack>

    <div className="my-2">
      <PaginationDataGrid id={coursMasterName} name="adminCourses" /> 
    </div>
    </>
  )
}
