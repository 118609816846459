import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import MultiSearchableSelect from '../../../components/form/MultiSearchableSelect';
import formValidationRules from '../../../helpers/formvalidationRules';
import FormButton from '../../../components/button/FormButton';
import { getCourseWithOutBatch } from '../../../service/admin/adminCourses.service';
import { Chip } from '@mui/material';
import { toast } from 'react-toastify';
import { createCourseBatch, deleteCourseBatch, getCourseExcludeBatch, getCourseExcludeFromBatch } from '../../../service/admin/adminBatch.service';

export default function AdminBatchCourseCE(props:{data:any}) {
    const [loading, setLoading] = useState(false);
    const [activeCourse, setActiveCourse] = useState([])
    const [courseList, setCourseList] = useState([]);
    // const fetchActiveCourses = async(batchId:string)=>{
    //     const get:any = await getCourseWithOutBatch(batchId)
    //     if(get?.status === "success"){
    //         setActiveCourse(get?.message)
    //     }
    // }
    const fetchBatches = async(batchId:string)=>{
        // setCourseList
        const get:any = await getCourseExcludeFromBatch(batchId);
        if(get?.status==="success"){
            let data:any = [];
            let activeCourse:any = [];
            get.message.map((item:any)=>{
              if(item.batchCourses.length !== 0){
                activeCourse.push(item)
              }else{
                data.push({value:item.id,label:item.name})
              }
            })
            setCourseList(data)
            setActiveCourse(activeCourse)
          }

    }
    useEffect(()=>{
        // fetchActiveCourses(props.data.id)
        fetchBatches(props.data.id )
    },[])

    const { handleSubmit, control } = useForm<any>();
      const onSubmit: SubmitHandler<any> = async(data) => {
        setLoading(true);
        const cr:any = await createCourseBatch(props.data.id,data.fk_course_id)
        if(cr.status ==="success"){
            fetchBatches(props.data.id )
            setLoading(false);
            return toast.success(cr.message)
        }
        setLoading(false)
      
      };    
    
      const handleDelete = async(id:string)=>{
            let del:any = await deleteCourseBatch(id)
            if(del?.status ==="success"){
                fetchBatches(props.data.id )
                toast.error(del.message)
            }
      }
  return (
    <>
        <div>
        <form onSubmit={handleSubmit(onSubmit)} className='mb-2'>
            <div className="grid grid-cols-12 gap-2 place-content-center" >
            <div className="col-span-12 sm:col-span-12 md:col-span-9 lg:col-span-8 ">
                <MultiSearchableSelect
                name="fk_course_id"
                control={control}
                options={courseList}
                rules={formValidationRules.required()}
                label="Select Courses"
                placeholder="Search.."
                 />
            </div>
            <div className=' col-span-12 sm:col-span-12 md:col-span-3 lg:col-span-4' >
                <br></br>
            <FormButton loading={loading} title="submit" />
            </div>
            </div>
            </form>
        </div>
        <div className='ml-3'>
            <p className='text-xl font-normal'>Active Courses : </p>
            <div className='my-3'>
                {
                    activeCourse.length > 0 ?
                        activeCourse.map((e:any,index:number)=>(
                            <span key={index}>
                            <Chip label={e?.name} sx={{marginX:0.5}} variant="outlined" onDelete={(()=>handleDelete(e?.batchCourses[0]?.id))} />
                            </span>
                        ))
                    :null
                }
            </div>
        </div>
    </>
  )
}
