import React, { useState,useRef, useEffect } from 'react'
import logo from '../../assets/images/logo.jpg'
import logoMini from '../../assets/images/logo-mini.jpg'
import { FaUserFriends,FaUserTie,FaRegStar,FaAngleLeft,FaAngleRight, FaHome, FaUser, FaMapMarkedAlt, FaVideo, FaUsers       } from "react-icons/fa";
import { NavLink, useLocation  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToBreadcrumbs } from '../../redux/breadcrumSlice';
import { isOpenSideBar } from '../../redux/commonSlice';
import { isMobileSideBar } from '../../redux/mobileSideBar';
import {  IoNewspaper, IoDocumentTextOutline  } from "react-icons/io5"
import { MdAddAlert, MdAutoGraph, MdCurrencyRupee, MdOutlineFeed, MdOutlineGroups, MdOutlineVideoLibrary } from "react-icons/md";
import { FaBookTanakh } from "react-icons/fa6";
import Cookies from 'js-cookie';
export default function SideBar(props:{
    data?:any
}) {
    const dispatch = useDispatch();
    const sideOpen: any = useRef();
    const sidebarOpen = useSelector((state:any) => state.commonSlice)
    const mobileSideBar = useSelector((state:any) => state.mobileSideBar)
    const [loginData, setLoginData] = useState<any>(undefined);
    

   let navItem:any =[];
   if(props.data === "admins"){
    navItem = [
        {"name":"Dashboard","link":"/admin/dashboard","icon":FaHome,},
        {"name":"Batches","link":"/admin/batches","icon":MdOutlineGroups},
        {"name":"Courses","link":"/admin/courses/master","icon":FaBookTanakh},
        {"name":"Videos","link":"/admin/videos","icon":MdOutlineVideoLibrary},
        {"name":"Test Series","link":"/admin/test-series","icon":IoNewspaper},
        {"name":"Live Classes","link":"/admin/live-class","icon":FaVideo},
        {"name":"Students","link":"/admin/students","icon":FaUserFriends},
        // {"name":"Teachers","link":"/admin/teachers","icon":FaUserTie},
        {"name":"Installments","link":"/admin/installments","icon":MdCurrencyRupee},
        {"name":"Exam Alerts","link":"/admin/exam-alert","icon":MdAddAlert},
        {"name":"Feeds","link":"/admin/feeds","icon":MdOutlineFeed},
        {"name":"Promotions","link":"/admin/promotions","icon":MdAutoGraph},
        // {"name":"Fees Invoices","link":"/admin/fees-invoice","icon":IoDocumentTextOutline},
    ];

   }
   if(props.data === "superAdmin"){
    navItem = [
        {"name":"Dashboard","link":"/super-admin/dashboard","icon":FaHome},
        {"name":"Center","link":"/super-admin/centers","icon":FaMapMarkedAlt},
        {"name":"Admins","link":"/super-admin/admins","icon":FaUsers},
    ];
   }
   const location = useLocation();
  
    useEffect(()=>{
        let m:any =  navItem.find((item:any)=>`/${item.link.split('/')[1]}/${item.link.split('/')[2]}`==='/'+location.pathname.split('/')[1]+'/'+location.pathname.split('/')[2])
        if(m){
         dispatch(addToBreadcrumbs(
             {
                 name:m?.name,
                 path:m?.link
             }
          ));
        }     
    })
   
    useEffect(() => {
        let data:any = Cookies.get('login');
        setLoginData(JSON.parse(data));
        const handleClickOutside = (event: any) => {
            if (sideOpen.current && !sideOpen.current.contains(event.target)) {
                dispatch(isMobileSideBar(false))
              }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      },[mobileSideBar]);
      
    const handleBreadcrum = (item:any)=>{
        dispatch(isMobileSideBar(false))
    }
    
    //    dispatch(addToBreadcrumbs(breadcrum));
    const handleSideBarStatus = ()=>{
        dispatch(isOpenSideBar(!sidebarOpen))
    }
  return (
    <>
    <aside className={` ${sidebarOpen ? 'w-[17%]  md:w-[22%] lg:w-[17%]' : 'w-auto'} fixed z-20 hidden sm:hidden  md:block lg:block dark:bg-dark-purple bg-gray-100 h-[100vh] overflow-y  overflow-scroll`}>
        <div className='flex justify-center flex-col    p-1'>
            {
                sidebarOpen ? <>
                <div className='flex justify-center'>
                <img src={loginData ? logo : logo} className={` w-[170px]  my-2`} alt="" />
                </div>
                 {
                loginData ? <>
                <p className='text-center dark:text-white text-dark font-bold text-xl'>{loginData?.name}</p>
                </> : null
            }
                </> : 
                <>
                 <img src={logoMini} className={` w-[50px]  my-2`}  alt="" />
                </>
            }
           
        </div>
        <div id="sidebar" className='navItems mt-10 md:mt-5'>
                {
                    navItem.length > 0 ? 
                        navItem.map((e:any,i:any)=>(
                            <NavLink 
                            to={e?.link}
                            className={`  flex items-center  dark:text-gray-300   rounded-md text-gray-200 my-2`}
                            end={false}
                            key={i}
                            >
                              
                               <div onClick={()=>handleBreadcrum(e)} className='  hover:cursor-pointer'> 
                               <div className=' p-1 flex flex-row gap-2  items-center justify-start'>
                                <div id="active-icon" className='pl-3'>
                                    {
                                    e?.icon !== null ? <>{
                                        <div id="active-icon" className='  text-dark dark:text-white mt-1'>
                                            {
                                                e?.icon ? 
                                                React.createElement(e?.icon, { size: "20" })
                                                :null
                                            }
                                            </div>
                                    }</> : <FaRegStar className='text-2xl' />
                                }
                                </div>
                                <p className={` ${sidebarOpen ? 'block mt-[5%] font-medium' : 'hidden'} p-1 mb-1 text-dark dark:text-white`}>{e.name}</p>
                               </div>
                               </div>
                            </NavLink>
                        ))
                    :null
                }
        </div>
        <div>
                {
                    sidebarOpen ?
                    <>
                    <FaAngleLeft onClick={(()=>handleSideBarStatus())}  className='fixed top-[3vh] left-[16%] lg:left-[16%] md:left-[20%] bg-white h-8 w-8 rounded-full p-1 shadow-lg hover:cursor-pointer hover:bg-gray-800 hover:text-white' />
                    </>
                    :
                    <FaAngleRight onClick={(()=>handleSideBarStatus())}  className='fixed top-[3vh] left-[4%] md:left-[6%] lg:left-[4%] bg-white h-8 w-8 rounded-full p-1 shadow-lg hover:cursor-pointer hover:bg-gray-800 hover:text-white' />
                }
        </div>
        </aside>
        <aside   ref={sideOpen} className={`${mobileSideBar ? 'block fixed z-20 w-[50%] bg-gray-100 h-[100vh] overflow-auto dark:bg-dark-blue' : 'hidden'}`}>
                <div className='  '>
                <div className='flex justify-center h-[10vh]'>
            <img src={logo} className=' h-[50px] my-2' alt="" />
        </div>
        <div id="sidebar" className='navItems mt-10 md:mt-5 '>
        {
                    navItem.length > 0 ? 
                        navItem.map((e:any,i:any)=>(
                            <NavLink 
                            
                            to={e?.link}
                            className={`  flex items-center mx-2 dark:text-gray-300   rounded-md text-gray-800 my-1`}
                            end={false}
                            key={i}
                         
                            >
                              
                               <div onClick={()=>handleBreadcrum(e)} className='  hover:cursor-pointer'> 
                               <div className='  p-2 flex flex-row gap-3   rounded-lg justify-start align-middle'>
                                <div id="active-icon" className=' bg-white dark:text-dark shadow-md rounded-md p-2'>
                                {
                                    e?.icon !== null ? <>{
                                        <div id="active-icon" className='  text-dark dark:text-white mt-1'>
                                            {
                                                e?.icon ? 
                                                React.createElement(e?.icon, { size: "20" })
                                                :null
                                            }
                                            </div>
                                    }</> : <FaRegStar className='text-2xl' />
                                }
                                </div>
                                <p className={` ${sidebarOpen ? 'block mt-[5%] font-medium' : 'hidden'} `}>{e.name}</p>
                               </div>
                               </div>
                            </NavLink>
                        ))
                    :null
                }
        </div>
                </div>
        </aside>
    
    </>
  )
}
