import React, { useState } from 'react'
import ReactPlayer from 'react-player'

export default function Player(props:{url:string}) {
    const [isReady, setIsReady] = useState(false);
    const [isBuffering, setIsBuffering] = useState(true);
    const handleReady = () => {
        setIsBuffering(false);
        setIsReady(true);
      };
    
      const handleBuffer = () => {
        setIsBuffering(true);
      };
  return (
    <ReactPlayer
          url={props.url}
          onReady={handleReady}
          controls
          width={"100%"}
          height={"250px"}
          playing
        />
  )
}
