import React, { useEffect, useState } from 'react'
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../../components/button/CustomButton'
import Models from '../../../../components/models/Models'
import AdminTestSubModulesCE from './AdminTestSubModulesCE'
import PaginationDataGrid from '../../../../components/tables/PaginationDataGrid'
import { useLocation } from 'react-router-dom'
import { randomNumber } from '../../../../helpers/helpers'

export default function AdminTestSubModules() {
  const [open, setOpen] = useState(false)
  const [moduleType, setModuleType] = useState<any>()
  const [change, setChange] = useState<any>(null)
  let location = useLocation()
  let moduleId = location.pathname.split("/")[6-1]
  let subMOd = location.pathname.split("/")[5-1]
 useEffect(() => {
  setModuleType(null)
  if(moduleId && subMOd){
    setModuleType("nmod")
    setChange(randomNumber(100))
  }else{
    setModuleType("smod")
  }
 },[setModuleType,location,moduleType,setChange])
  return (
    <>
   
     <BreadcrumbBack title="Test Series Submodules">
<CustomButton
    onClick={(()=>setOpen(!open))}
   title="Create Sub Module"
   icon={true}
   iconName="create"
 />
</BreadcrumbBack>
<div className="my-2">
      <div className="my-2">
      {
        change ? 
          moduleType !== "smod" ?
          <PaginationDataGrid name="adminTestSeriesNSubModules" dataId={subMOd} id={moduleId} />
          :null
        :null

      }
      {
        moduleType === "smod" ? 
        <PaginationDataGrid name="adminTestSeriesSubModules" dataId={subMOd} />
        :null
      }
      </div>
      
     
   </div>
<Models  name="Create Test Series Sub Module" isOpen={open} isClose={setOpen}  >
      <AdminTestSubModulesCE type='create' open={setOpen} data={{mod:subMOd,submod:moduleId}  } />
   </Models>

    </>
  )
}
