import instance from "../instace";

export const createAdminBatch = async(name:string,status:string)=>{
    try{
        const get:any = await instance.post('/api/admin/batch',{
           name,status
        })
        return get.data;
     }catch(e){
        return false;
    }
}
export const showAdminBatch = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/batch/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminBatch = async(id:string,name:string,status:string)=>{
    try{
        const get:any = await instance.put(`/api/admin/batch/${id}`,{
            name,status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateStatusAdminBatch = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/batch/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminBatch = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/batch/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}

export const getAdminBatchService = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/batch/get?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}

export const getStudentsWithOutBatch = async(id:string,page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/batch/students/withoutbatch/${id}?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}

export const createAdminBatchStudents = async(batchid:string,students:any)=>{
    try{
        const get:any = await instance.post('/api/admin/batch/student/add',{
            batchid,students
        })
        return get.data;
     }catch(e){
        return false;
    }
}
export const getStudentsWithBatch = async(id:string,page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/batch/students/withbatch/${id}?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const deleteSingleAdminBatchStudents = async(batchId:string,id:any)=>{
    try{
        const get:any = await instance.post('/api/admin/batch/student/batch/remove/single',{
            batchId,id
        })
        return get.data;
     }catch(e){
        return false;
    }
}
export const deleteMultipleAdminBatchStudents = async(batchId:string,id:any)=>{
    try{
        const get:any = await instance.post('/api/admin/batch/student/batch/remove/multiple',{
            batchId,id
        })
        return get.data;
     }catch(e){
        return false;
    }
}
export const deleteEmptyAdminBatchStudents = async(batchId:string)=>{
    try{
        const get:any = await instance.post('/api/admin/batch/student/batch/remove/empty',{
            batchId
        })
        return get.data;
     }catch(e){
        return false;
    }
}
export const getAllBatchStudents = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/batch/student/download/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const getActiveBatchs = async()=>{
    try{
        const get:any = await instance.get(`/api/admin/batch/all/active`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const getCourseExcludeBatch = async(id:string,stu?:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/batch/exclude/course/${id}/${stu ? stu : '123'}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const getCourseExcludeFromBatch = async(id:string,stu?:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/batch/exclude/course/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}

export const createCourseBatch = async(id:string,fk_course_id:any)=>{
    try{
        const get:any = await instance.post(`/api/admin/batch/include/course/${id}`,{
            fk_course_id
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteCourseBatch = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/batch/delete/batchcourse/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
