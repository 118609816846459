import { AdminTypes } from "../../types/AdminTypes";
import instance from "../instace";

export const AdminLoginService = async(email: string, password: string)=>{
    try{
        const get:any = await instance.post('/api/admin/login',{
            email, password,
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const createAdminService = async(data:AdminTypes)=>{
    try{
        const get:any = await instance.post('/api/admin/register',{
            name:data.name,
            email:data.email,
            phone:data.phone,
            status:data.status,
            center_id:data.center_id
        })
        return get.data;
    }catch(e){
        return false;
    }
}
export const getAdminService = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/get-list?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAdminService = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminService = async(id:string,data:AdminTypes)=>{
    try{
        const get:any = await instance.put(`/api/admin/edit/${id}`,{
            name:data.name,
            email:data.email,
            phone:data.phone,
            status:data.status,
            center_id:data.center_id
        })
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateStatusAdminService = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/status/${id}`,{
            status:status,
        })
        return get.data;
    }catch(e){
            return false;
    }
}

export const deleteAdminService = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/delete/${id}`)
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateAdminPasswordService = async(password:string,confirmPassword:string)=>{
    try{
        const get:any = await instance.put(`/api/admin/login/password`,{
            password:password,
            confirmPassword:confirmPassword,
            type:"login"
        })
        return get.data;
    }catch(e){
            return false;
    }
}