import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import  Cookies  from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { AdminLoginService } from '../../service/admin/admin.service';
import { setAuthToken } from '../../service/AuthConfig';
import {  schoolLogin } from '../../redux/loginSlice';
import Logo from "../../assets/icons/logo.jpg"
import { inputField } from '../../helpers/Classes';
import LoginButton from '../../components/button/LoginButton';
export default function AdminLogin() {

    const [email,setEmail] = useState(undefined);
    const [password,setPassword] = useState(undefined);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch(); 
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(()=>{
        Cookies.set('role','admin')
        document.title = 'Login | LMS';
    },[]);

    const handleSubmit = async(e:any)=>{
        e.preventDefault()
        setLoading(true)
        if(email === undefined){
            return Swal.fire({
                icon: "error",
                text: "login Id required",
              });
        }
        if(password === undefined){
            return Swal.fire({
                icon: "error",
                text: "password required",
              });
        }

        let login = await AdminLoginService(email,password);
        if(login.status==="error"){
          return  setLoading(false)
          }
          if(login.status==="success"){
            Cookies.set(`role`, login.message.role);
            Cookies.set("token", login.message.token);
            Cookies.set("login",JSON.stringify(login.message));
            console.log(login.message.password_update)
            
            setAuthToken(login.message.token)
            dispatch(schoolLogin())
            if(login.message.password_update === false){
                return navigate("/admin/login-password/update")
             }
            navigate("/admin/dashboard")
            setLoading(false)
              return toast.success(login.message);    
          }else{
            setLoading(false)
          }
        setLoading(false)

        
    }

  return (
        <>
        <div className='container-login100'>
                <div className=' lg:min-w-[25vw]'>
                    <div className='bg-white rounded-3xl shadow-lg p-5 sm:p-5 md:p-10 lg:p-10 w-full'>
                        <div className="flex justify-center mb-4">
                            <img src={Logo} alt="chahal Academy Logo" />
                        </div>
                        <p className=' text-2xl font-bold text-center'>Welcome Admin</p>
                        <p className=' font-semibold text-gray-500 mt-5 text-center'>Login to continue..</p>
                        <form className='my-5' onSubmit={handleSubmit}>
                        <div className="flex mb-2 flex-row gap-3 ">
                        <div className="flex flex-col mb-3 w-[100%]">
                            <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Email <sup className='text-red-600'>*</sup></label>
                            <input type="text"
                            required
                            onChange={((e:any)=>setEmail(e.target.value))}
                            className={`${inputField} `}  placeholder='Login Id'   />
                            </div>
                        </div>
                        <div className="flex mb-3 flex-row gap-3 ">
                        <div className="flex flex-col mb-3 w-[100%]">
                            <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Password <sup className='text-red-600'>*</sup></label>
                            <input type="password" 
                            required
                            onChange={((e:any)=>setPassword(e.target.value))}
                            className={`${inputField} `}  placeholder='password'   />
                            </div>
                        </div>
                        <div className="flex mb-3 flex-row gap-3 ">
                        <div className="flex flex-col mb-3 w-[100%] ">
                        <LoginButton icon={true} title='Login'  loading={loading} />
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
        </div>
        </>
  )
}
