import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BatchStuSubsciptionTypes, studentPaymentTypes } from "../../../types/AdminTypes";
import { getCourseExcludeBatch } from "../../../service/admin/adminBatch.service";
import MultiSearchableSelect from "../../../components/form/MultiSearchableSelect";
import formValidationRules from "../../../helpers/formvalidationRules";
import FormButton from "../../../components/button/FormButton";
import Input from "../../../components/form/Input";
import SearchableSelect from "../../../components/form/SearchableSelect";
import moment from "moment";
import SimpleTable from "../../../components/tables/SimpleTable";
import { createInvocePayments, getpaymentsHistory } from "../../../service/admin/adminStudent.service";
import { toast } from "react-toastify";
import { toBoolean } from "../../../helpers/helpers";
import { FaClock, FaRubleSign, FaRupeeSign } from "react-icons/fa";

export default function AdminBatchPayments(props: { data?: any; open: any }) {
  const [loading, setLoading] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState<any>(null);
  const [instAmount, setInstAmount] = useState<any>(0);
  const [pendingInstAmount, setPendingInstAmount] = useState(0);
  const [admissionDate, setAdmissionDate] = useState<any>()
  const [lastPayment, setLastPayment] = useState<any>()
  const [tableRow, setTableRow] = useState([])
  const [activetab, setActiveTab] = useState({
    id: 1,
    name: "History",
    title: "history",
  });
  const [historyList, setHistoryList] = useState<any>([]);

  const fetchBatches = async (batchId: string,stu:string) => {
    const get: any = await getCourseExcludeBatch(batchId,stu);
    if (get?.status === "success") {
      let data: any = [];
      get.message.map((item: any) => {
       if(item.studentSubscription.length !== 0){
        data.push({
            value: item.id,
            label: item.name,
            amount: item.amount,
            duration: item.duration,
            subId:item.studentSubscription[0].id
          }); 
       }
      });
      setCourseList(data);
    }
  };
  const fetchpaymentHistory = async(courseId:string,studentId:string)=>{
        const get:any = await getpaymentsHistory(courseId,studentId)
        if(get?.status === "success"){
            setHistoryList(get?.message)
            if(get?.message?.stundetPayemts?.length > 0){
                let tbdata:any = [];
                get?.message?.stundetPayemts.map((item:any,index:number)=>{
                  if(index === 0){
                    setAdmissionDate(moment(item.date).format('YYYY-MM-DD'))
                }
                if(get?.message?.stundetPayemts?.length === index+1){
                    setLastPayment({
                        paid:item.paid,
                        remaining:item.remaining,
                    })
                }
                  tbdata.push([
                    index+1,
                    item.paid,
                   item.remaining,
                    moment(item.date).format('YYYY-MM-DD'),
                    item.complete ? 'Completed' : '-'
                  ])
                  })
                  setTableRow(tbdata)
            }
        }
  }
  useEffect(() => {
    fetchBatches(props.data.id,props.data?.stu);
  }, []);
  useEffect(()=>{
        if(selectedCourse?.value){
            fetchpaymentHistory(selectedCourse?.value,props.data?.stu);
        }
  },[setSelectedCourse,selectedCourse])
  const { handleSubmit, control } = useForm<studentPaymentTypes>({
    values:{
      fk_subscription_id:selectedCourse?.subId,
      exp_date:"",
      inst_amount:lastPayment?.remaining,
      pending_amount:0,
      complete:null
  }
  });
  const onSubmit: SubmitHandler<studentPaymentTypes> = async (
    data: studentPaymentTypes
  ) => {
    setLoading(true);
    if(historyList?.course_amount < data.inst_amount){
      return toast.error("Payment amount should not be more than course amount.")
  }
  if(historyList?.course_amount < data.pending_amount){
      return toast.error("Pending amount should not be more than course amount.")
  }
    data.fk_subscription_id = selectedCourse.subId
    data.complete = toBoolean(data.complete.value)
      const cr:any = await createInvocePayments(data)
      if(cr.status ==="success"){
        if(selectedCourse?.value){
          fetchpaymentHistory(selectedCourse?.value,props.data?.stu);
        }
        setActiveTab({
          id: 1,
          name: "History",
          title: "history",
        })
          setLoading(false);
          return toast.success(cr.message)
      }
    setLoading(false);
  };
  const tablist = [
    { id: 1, name: "History", title: "history" },
    { id: 0, name: "Add Payment", title: "newinvoice" },
  ];
  const historCols = [
    "id",
    "Paid Amount",
    "Pending Amount",
    "Date",
    "Complete",
  ];
  return (
    <>
      <div className="mb-2 min-h-[130px]">
        <div className="grid grid-cols-12 gap-2 place-content-center ">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 ">
            <SearchableSelect
              name="fk_course_id"
              control={control}
              options={courseList}
              onFetch={setSelectedCourse}
              rules={formValidationRules.required()}
              label="Select Courses"
              placeholder="Search.."
            />
          </div>
        
         <div className="col-span-12">
         {
            historyList && selectedCourse ? 
            <>
            <hr />
            <div className="bg-white p-3 rounded-lg my-2">
              <div className="flex flex-row gap-2 items-center justify-between">
              <div className="flex flex-row items-center gap-2" >
                 <b>Admission Date :</b> <p className="flex flex-row items-center" >{admissionDate}</p>
                </div>
                <div className="flex flex-row items-center gap-2" >
                <b> Expiry Date  :</b> {moment(historyList?.expiry_date).format('YYYY-MM-DD')}
                </div>
                <div className="flex flex-row items-center gap-2" >
                 <b>Total Amount :</b> <p className="flex flex-row items-center" >{historyList?.course_amount}</p>
                </div>
              </div>
            </div>
            </>
            :null
          }
         </div>
        </div>
        <div>
          {selectedCourse ? (
            tablist.length > 0 ? (
              <>
                <div className="flex flex-row gap-4 items-center bg-gray-300 p-2 ">
                  {tablist.map((e: any) => (
                    <>
                      <button
                        onClick={() => setActiveTab(e)}
                        className={`${
                          activetab.id === e.id
                            ? "hover-purple-gradient text-white"
                            : "bg-gray-200 dark:bg-secondary"
                        } p-2 font-semibold rounded-md hover:shadow-xl`}
                      >
                        {e.name}
                      </button>
                    </>
                  ))}
                </div>
              </>
            ) : null
          ) : null}
          {selectedCourse ? (
            activetab.id === 1 ? (
              tablist.length > 0 ? (
                <div className="bg-gray-300 p-2">
                 
                  <ul>
                    <SimpleTable rows={tableRow} cols={historCols} />
                  </ul>
                </div>
              ) : null
            ) : null
          ) : null}
          {selectedCourse ? (
            activetab.id === 0 ? (
              tablist.length > 0 ? (
                <form onSubmit={handleSubmit(onSubmit)} className="mb-2 mt-2">
                  <div className="grid grid-cols-12 gap-2">
                    
                    <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 ">
                      <Input
                        name="exp_date"
                        type="date"
                        control={control}
                        rules={{
                          ...formValidationRules.required(),
                        }}
                        label="Expiry Date"
                        defaultValue=""
                        placeholder="expiry date"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 ">
                      <Input
                        name="inst_amount"
                        type="number"
                        control={control}
                        rules={{
                          ...formValidationRules.required(),
                        }}
                        label="Paid Amount"
                        defaultValue=""
                        placeholder="paid amount"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 ">
                      <Input
                        name="pending_amount"
                        type="number"
                        control={control}
                        rules={{
                          ...formValidationRules.required(),
                        }}
                        label="Remaining Amount"
                        defaultValue=""
                        placeholder="remaining amount"
                      />
                    </div>
                    <div className=' col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12' >
            <SearchableSelect
                name="complete"
                control={control}
                options={[
                    {value:'true',label:"Complete",},
                    {value:'false',label:"Not Complete",}
                ]}
                rules={formValidationRules.required()}
                label="Payment Status"
                placeholder="Search.."
                 />
            </div>
            <div className="mt-3 w-full col-span-12">
            <FormButton loading={loading} title={'Submit'} />
            </div>
                  </div>
                </form>
              ) : null
            ) : null
          ) : null}
        </div>
      </div>
    </>
  );
}
