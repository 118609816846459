import React, { useEffect, useState } from 'react'
import { createAdminBatch, editAdminBatch, showAdminBatch } from '../../../service/admin/adminBatch.service';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BatchTypes } from '../../../types/AdminTypes';
import { statusList, toBoolean } from '../../../helpers/helpers';
import { setContentUpdate } from '../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';
import RadioButton from '../../../components/form/RadioButton';
import FormButton from '../../../components/button/FormButton';

export default function AdminBatchCE(props:{type:string,data?:any,open:any}) {
    const [pageLoad, setPageLoad]= useState(false);
    const [fetchData, setFetchData] =useState<any>();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState({
        button:"Create New Batch",
    })

    const dispatch = useDispatch()
    
    const fetchBatchDetail = async(id:string)=>{
        const get:any = await showAdminBatch(id)
      if(get?.status === "success"){
        setFetchData(get.message)
      }
    }
    useEffect(()=>{ 

        setPageLoad(true)
        if(props.type === "edit"){
            fetchBatchDetail(props.data.id)
            setPageContent({
                button:"Update Batch"
            })
        }
        // fetchInstituteCenter()
        setPageLoad(false)
      },[])


      const { handleSubmit, control } = useForm<BatchTypes>({
        values:{
            name:fetchData?.name,
            status:`${fetchData?.status}`,
        }
      });
      const onSubmit: SubmitHandler<BatchTypes> = async(data) => {
        setLoading(true);
        let status_:any =  toBoolean(data.status);
        let fetch:any;
       if(props.type==="create"){
        fetch = await createAdminBatch(data.name,status_);
       }
       if(props.type === "edit"){
        fetch = await editAdminBatch(fetchData?.id,data.name,status_);
       }
       if(fetch?.status=== "success"){
        setLoading(false)
        dispatch(setContentUpdate({
            id:Math.random() * (100000 - 111111)
          }))
          props.open(false)
        return toast.success(fetch.message);
      }
      setLoading(false)
      
      };    
      


  return (
    <>
     <div className="my-2">
    <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
    <Input 
       name="name"
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="Name"
       placeholder="Name"
      />
       <RadioButton
        name="status"
        control={control}
        rules={{...formValidationRules.required()}}
        options={statusList}
        label="status"
      />
        <br/>
        <FormButton loading={loading} title={pageContent.button} />

    </form>
    </div>
    </>
  )
}

