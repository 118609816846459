import React, { useState } from 'react'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../components/button/CustomButton'
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid'
import Models from '../../../components/models/Models'
import AdminPromotionalCE from './AdminPromotionalCE'

export default function AdminPromotional() {
    const [open,setOpen] = useState(false)
  return (
    <>
    <BreadcrumbBack title={`Promotional Content`}>
    <CustomButton onClick={(()=>setOpen(!open))} bg='bg-purple-800'  title='Create Promotional Content' icon={true} iconName='create'    />
   </BreadcrumbBack>
    <div className="my-3">
    <PaginationDataGrid  name="adminPromotionalContent" /> 
    </div>

    <Models name='Create Promotional Content' isOpen={open} isClose={setOpen}  >
        <AdminPromotionalCE type='create' open={setOpen} />
    </Models>
    </>
  )
}
