import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { BatchStuSubsciptionTypes } from '../../../types/AdminTypes';
import { getCourseExcludeBatch } from '../../../service/admin/adminBatch.service';
import MultiSearchableSelect from '../../../components/form/MultiSearchableSelect';
import formValidationRules from '../../../helpers/formvalidationRules';
import FormButton from '../../../components/button/FormButton';
import Input from '../../../components/form/Input';
import SearchableSelect from '../../../components/form/SearchableSelect';
import moment from 'moment';
import { createSubscription } from '../../../service/admin/adminStudent.service';
import { toast } from 'react-toastify';
import { toBoolean } from '../../../helpers/helpers';

export default function AdminBatchSubscription(props:{data?:any,open:any}) {
      const [loading, setLoading] = useState(false);
      const [courseList, setCourseList] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState<any>(null);
    const [instAmount, setInstAmount] = useState<any>(0)
    const [pendingInstAmount, setPendingInstAmount] = useState(0)
    
      const fetchBatches = async(batchId:string,stu:string)=>{
        const get:any = await getCourseExcludeBatch(batchId,stu);
        if(get?.status==="success"){
            let data:any = [];
            get.message.map((item:any)=>{
                if(item.studentSubscription.length === 0){
                    data.push({value:item.id,label:item.name,amount:item.amount,duration:item.duration})
                }
            })
            setCourseList(data)
          }
    }
    useEffect(()=>{
        fetchBatches(props.data.id,props.data?.stu )
    },[])
    const { handleSubmit, control } = useForm<BatchStuSubsciptionTypes>({
        values:{
            fk_course_id:selectedCourse?.value,
            total_amount:selectedCourse ? selectedCourse.amount : 0,
            exp_date:selectedCourse?.duration ? moment().add(selectedCourse?.duration,'days').format('YYYY-MM-DD') : '',
            inst_amount:instAmount,
            pending_amount:instAmount === 0 ? pendingInstAmount : selectedCourse?.amount ? selectedCourse.amount - instAmount : 0,
            fk_student_id:"",
            complete:"",
        }
    });
    const onSubmit: SubmitHandler<BatchStuSubsciptionTypes> = async(data:BatchStuSubsciptionTypes) => {
      setLoading(true);
      console.log(data)
        if(selectedCourse?.amount < data.inst_amount){
            return toast.error("Instalment amount should not be more than course amount")
        }
        if(selectedCourse?.amount > data.pending_amount){
            return toast.error("Pending amount should not be more than course amount")
        }
        data.complete = toBoolean(data.complete)
        data.fk_student_id = props.data?.stu
      const cr:any = await createSubscription(data)
      if(cr?.status ==="success"){
          setLoading(false);
          props.open(false)
          return toast.success(cr.message)
      }
      setLoading(false)
    
    };    
    const handleChea = (data:any)=>{
        setSelectedCourse(data)
    }
  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)} className='mb-2'>
    <div className="grid grid-cols-12 gap-2 place-content-center" >
            <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 ">
            <SearchableSelect
                name="fk_course_id"
                control={control}
                options={courseList}
                onFetch={handleChea}
                rules={formValidationRules.required()}
                label="select couse"
                placeholder="Search.."
                 />
            </div>
            <div className=' col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6' >
            <Input 
                name="total_amount"
                type='number'
                control={control}
                rules={{
                    ...formValidationRules.required(),
                }}
                label="Total Amount"
                defaultValue=''
                placeholder="total amount"
                />
            </div>
            </div>
            <div className="grid grid-cols-12 gap-2 place-content-center" >
            <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 ">
            <Input 
                name="exp_date"
                type='date'
                control={control}
                rules={{
                    ...formValidationRules.required(),
                }}
                label="Expiry Date"
                defaultValue=''
                placeholder="expiry date"
                />
            </div>
            <div className=' col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6' >
            <Input 
                name="inst_amount"
                type='number'
                control={control}
                onChange={setInstAmount}
                rules={{
                    ...formValidationRules.required(),
                }}
                label="Installment  Amount"
                defaultValue=''
                placeholder="installment  amount"
              
                />
            </div>
            <div className=' col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6' >
            <Input 
                name="pending_amount"
                type='number'
                control={control}
                rules={{
                    ...formValidationRules.required(),
                }}
                label="Pending Amount"
                defaultValue=''
                placeholder="pending amount"
                />
            </div>
            <div className=' col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6' >
            <SearchableSelect
                name="complete"
                control={control}
                options={[
                    {value:'true',label:"Complete",},
                    {value:'false',label:"Not Complete",}
                ]}
                rules={formValidationRules.required()}
                label="Payment Status"
                placeholder="Search.."
                 />
            </div>
            <div className="mt-3 w-full col-span-12">
            <FormButton loading={loading} title={'Submit'} />
            </div>
            </div>
    </form>
    </>
  )
}
