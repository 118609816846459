import React, { useEffect, useState } from 'react'
import { set, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { statusList, toBoolean } from '../../../../helpers/helpers';
import { setContentUpdate } from '../../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';
import RadioButton from '../../../../components/form/RadioButton';
import FormButton from '../../../../components/button/FormButton';
import SearchableSelect from '../../../../components/form/SearchableSelect';
import Swal from 'sweetalert2';
import { adminTestSeriesSubModulesTypes } from '../../../../types/AdminTypes';
import { useLocation } from 'react-router-dom';
import { createAdminTestSeriesSubModules, editAdminTestSeriesSubModules, showAdminTestSeriesSubModules } from '../../../../service/admin/adminTestSeries.service';

export default function AdminTestSubModulesCE(props: {
  type: string;
  data?: any;
  open: any;
}) {

  const [pageLoad, setPageLoad]= useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [url, setUrl] = useState<any>();
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] = useState<any>(false);
  const [pageContent, setPageContent] = useState({
    button:"Create Course Master",
})
  const [subModuleType, setSubModuleType] = useState(false)
    
    const fetchBatchDetail = async(id:string)=>{
        const get:any = await showAdminTestSeriesSubModules(id)
      if(get?.status === "success"){
        setUrl(get.message?.image)
        setFetchData(get.message)
        if(get.message?.marks !== null){
          setSubModuleType(true)
        }else{
          setSubModuleType(false)
        }
      }
    }
    let location = useLocation()
    let moduleId = location.pathname.split("/")[location.pathname.split("/").length-1]
    useEffect(()=>{ 

        setPageLoad(true)
        if(props.type === "edit"){
            fetchBatchDetail(props.data.id)
            setPageContent({
                button:"Update Course Master"
            })
        }
        // fetchInstituteCenter()
        setPageLoad(false)
      },[])

  const { handleSubmit, control } = useForm<any>({
    values: {
      name: fetchData?.name,
      status: `${fetchData?.status}`,
      attempt:`${fetchData?.attempt ? fetchData?.attempt : 0}`,
      types : `${fetchData?.marks !== null ? 'file' : 'dir' }`,
      marks:fetchData?.marks,
      negative_marks:fetchData?.negative_marks,
      duration:fetchData?.duration,
      
    },
  });
  const onSubmit: SubmitHandler<any> = async (data:any) => {
    setLoading(true);
    console.log(data)
    let status_: any = toBoolean(data.status);
    let fetch: any;
   data.status = status_
    if(subModuleType === true){
      props.open(false);
      if(data.marks === ""){
        return Swal.fire({
          title: 'Error',
          text: 'Marks field is required',
          icon: 'error',
        })
      }
      if(data.negative_marks === ""){
        props.open(false);
        return Swal.fire({
          title: 'Error',
          text: 'Marks field is required',
          icon: 'error',
        })
      }
      if(data.duration === ""){
        props.open(false);
        return Swal.fire({
          title: 'Error',
          text: 'Marks field is required',
          icon: 'error',
        })

      }
    }
    
    let sendingData:adminTestSeriesSubModulesTypes = {
        fk_testModule_id:moduleId,
        name: data.name,
        status: status_,
        attempt: parseInt(data.attempt),
        marks: subModuleType ? parseInt(data.marks) : null,
        negative_marks: subModuleType ? parseInt(data.negative_marks) : null,
        duration: subModuleType ? parseInt(data.negative_marks) : null,
        fk_dir_id: "file",

    }
    if(subModuleType === false){
      sendingData = {
        fk_testModule_id:moduleId,
        name: data.name,
        status: status_,
        attempt: parseInt(data.attempt),
        fk_dir_id:"folder",
      }
    }
    sendingData.fk_testModule_id = props?.data.mod
    if(props?.data.mod && props?.data.submod){
      sendingData.fk_nmod_id = props?.data.submod
    }
       if(props.type==="create"){
        fetch = await createAdminTestSeriesSubModules(sendingData);
       }
       if(props.type === "edit"){
        delete sendingData.fk_testModule_id;
        fetch = await editAdminTestSeriesSubModules(props.data.id,sendingData);
       }
    if (fetch?.status === "success") {
      setLoading(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      props.open(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };
  const handleFilesType =(data:any)=>{
    if(data?.value === "dir"){
      setSubModuleType(false)
    }else{
      setSubModuleType(true)
    }
  }
    return (
     <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <Input
          name="name"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Name"
          placeholder="Name"
        />
       
       <Input
          name="attempt"
          control={control}
          label="Attempt"
          placeholder="attempt"
        />
         <SearchableSelect 
          name="types"
          control={control}
          options={[
            {value:"dir",label:"Folder"},
            {value:"file",label:"File"}
          ]}
          onFetch={handleFilesType}
          rules={formValidationRules.required()}
          label="Select Submodule Type"
          placeholder="Search.."
       

         />
         {
          subModuleType ? 
          <div className={`${subModuleType === true ? 'block' : ' hidden'}`}>
            <Input
          name="marks"
          control={control}
          label="Marks"
          placeholder="marks"
        />
          <Input
          name="negative_marks"
          control={control}
          label="Negative Marks"
          placeholder="negative marks"
        />
          <Input
          name="duration"
          control={control}
          label="Duration"
          placeholder="duration"
        />
          </div>
          :
          null
         }
      <div className="">
       <RadioButton
        name="status"
        control={control}
        rules={{...formValidationRules.required()}}
        options={statusList}
        label="status"
      />
       </div>
       <br/>
       <FormButton loading={loading} title={pageContent.button} />
      </form>
    </>
  )
}
