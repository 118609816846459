import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import MultiSearchableSelect from '../../../../components/form/MultiSearchableSelect';
import FormButton from '../../../../components/button/FormButton';
import SearchableSelect from '../../../../components/form/SearchableSelect';
import { createImportVimeoVideoImport, getAdminVideosSubModuleActive, getVideosActiveModule, getVideosActiveSubModuleByModule } from '../../../../service/admin/adminVideoes.service';
import { getAllCourseActive } from '../../../../service/admin/adminCourses.service';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';

export default function AdminVimeoImport(props:{data?:any}) {
    const [loading, setLoading] = useState(false);
    const [show,setShow]  = useState<any>();
    const [moduleData, setModuleData] = useState<any>([]);
    const [subModuleData, setSubModuleData] = useState<any>([])
    const [moduleSubData, setModuleSubData] = useState<any>([])
    const { handleSubmit, control } = useForm<any>();
    const onSubmit: SubmitHandler<any> = async(data:any) => {
      setLoading(true);
      let fetch:any;
        let datam:any = [];
        if(data?.fk_submodule_id?.length > 0){
            data.fk_submodule_id.map((item:any)=>{
                let find:any = moduleSubData.find((i:any)=>i.value === item)
                datam.push({
                    m:find.module,
                    s:find.value
                })
            })
}
// setLoading(false)
// return false;
        let datas:any = {
            fk_mod_id:data.fk_mod_id.value,
            fk_module_id:datam,
            fk_sub_module_id:data.fk_sub_module_id,
            fk_course_id:data.fk_course_id,
            videos:props.data,
            restriction:data.restriction
        }
      fetch = await createImportVimeoVideoImport(datas);
     if(fetch?.status=== "success"){
      setLoading(false)
      // props.isOpen(false)
      return toast.success(fetch.message);
    }
    setLoading(false)
    
    };  
    const fetchModules = async()=>{
        const get:any = await getVideosActiveModule();
        if(get?.status === "success"){
            let data:any = [];
            get.message.map((item:any)=>{
                data.push({value:item.id,label:item.name})
              })
              setModuleData(data)
            //   fetchSubModules();
        }
    }
    const fetchSubModules = async(id:string)=>{
        const get:any = await getVideosActiveSubModuleByModule(id);
        if(get?.status === "success"){
            let data:any = [];
            get.message.map((item:any)=>{
                data.push({value:item.id,label:item.name,module:item.fk_videoModule_id})
              })
              let newarray = [...moduleSubData,...data]
              setModuleSubData(newarray)
        }
    }
    const handlefetch = async(data:any)=>{
        if(data?.selected.length>0){
            if(data?.removed === undefined){
                fetchSubModules(data?.selected[data?.selected.length-1])
            }
            else{
                let updated =  moduleSubData.filter((item:any)=>item.module !== data?.removed)
                setModuleSubData(updated)
            }
           }else{
            setModuleSubData([])
            
           }
    }
    const fetchOnlySubModule = async()=>{
        const get:any = await getAdminVideosSubModuleActive()
        if(get?.status === "success"){
            let data:any = [];
            get.message.map((item:any)=>{
                data.push({value:item.id,label:item.name})
              })
              setSubModuleData(data)
        }
    }
    const fetchCourses = async()=>{
        const get:any = await getAllCourseActive()
        if(get?.status === "success"){
            let data:any = [];
            get.message.map((item:any)=>{
                data.push({value:item.id,label:item.name})
              })
              setSubModuleData(data)
        }
    }
    const handleFetchs = async(data:any)=>{
            
            setShow(data?.value)
            if(data?.value === "course"){
                fetchCourses()
            }else if(data?.value === "module"){
                fetchModules()
            }else if(data?.value === "submodule"){
                fetchOnlySubModule()
            }
    }
  return (
    <>
     <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
     {/* restriction */}
                <Input 
                        type='number'
                name="restriction"
                control={control}
                rules={{
                    ...formValidationRules.required(),
                }}
                label="Restriction"
                placeholder="restriction"
                />
            <SearchableSelect
                   name="fk_mod_id"
                   control={control}
                   options={[
                    {label:"Course",value:"course"},
                    {label:"Module",value:"module"},
                    {label:"Sub Module",value:"submodule"}
                   ]}
                   onFetch={handleFetchs}
                   label="Select Module"
               />
               {
                show === "submodule"?  
                <MultiSearchableSelect
                name="fk_sub_module_id"
                control={control}
                options={subModuleData}
                label="Select SubModule"
            />
                :null
               }
                {
                show === "course"?  
                <MultiSearchableSelect
                name="fk_course_id"
                control={control}
                options={subModuleData}
                label="Select Course"
            />
                :null
               }
                  {
                show === "module"?  
              <>
                <MultiSearchableSelect
                name="fk_module_id"
                control={control}
                options={moduleData}
                onFetch={handlefetch}
                label="Select module"
            />
                <MultiSearchableSelect
                name="fk_submodule_id"
                control={control}
                options={moduleSubData}
                label="Select sub module"
            />
              </>
                :null
               }
                <div className="mt-10">
                <FormButton loading={loading}  title={'submit'} />
                </div>
            </form>
    </>
  )
}
