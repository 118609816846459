import React, { useState } from 'react'
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack'
import CustomButton from '../../../components/button/CustomButton'
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid'
import Models from '../../../components/models/Models'
import AdminFeedsCE from './AdminFeedsCE'

export default function AdminFeeds() {
    const [open,setOpen] = useState(false)
  return (
    <>
    <BreadcrumbBack title={`Feed`}>
    <CustomButton onClick={(()=>setOpen(!open))} bg='bg-purple-800'  title='Create Feed' icon={true} iconName='create'    />
   </BreadcrumbBack>
    <div className="my-3">
    <PaginationDataGrid  name="adminFeeds" /> 
    </div>

    <Models name='Create Feed' isOpen={open} isClose={setOpen}  >
        <AdminFeedsCE type='create' open={setOpen} />
    </Models>
    </>
  )
}
