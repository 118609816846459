import React, { useState } from 'react'
import AdminCourseVideoRelation from './AdminCourseVideoRelation'
import AdminCourseTestSeriesRelation from './AdminCourseTestSeriesRelation'
import AdminCourseLiveClassRelation from './AdminCourseLiveClassRelation'

export default function AdminCourseRelation(props:{data:any}) {

    const [activeTab, setActiveTab] = useState(0)

  return (
    <>
        <div className='flex flex-row gap-2'>
            <button onClick={(()=>setActiveTab(0))} className={`${activeTab === 0 ? 'text-white bg-purple2' : 'bg-gray-300 text-dark'} px-3 py-2 rounded-lg `}>Video </button>
            <button onClick={(()=>setActiveTab(1))} className={`${activeTab === 1 ? 'text-white bg-purple2' : 'bg-gray-300 text-dark'} px-3 py-2 rounded-lg `}>Test Series </button>
            <button onClick={(()=>setActiveTab(2))} className={`${activeTab === 2 ? 'text-white bg-purple2' : 'bg-gray-300 text-dark'} px-3 py-2 rounded-lg `}>Live Class  </button>
        </div>
        <div className="my-2">
            {
                activeTab === 0 && <AdminCourseVideoRelation id={props.data.id} />
            }
            {
                activeTab === 1 && <AdminCourseTestSeriesRelation id={props.data.id} />
            }
            {
                activeTab === 2 && <AdminCourseLiveClassRelation id={props.data.id} />
            }
        </div>
    </>
  )
}
