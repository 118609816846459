import { adminExamAlertsTypes, adminPromotionalImageTypes, AdminTypes } from "../../types/AdminTypes";
import instance from "../instace";

export const createAdminpromotional = async(data:adminPromotionalImageTypes)=>{
    try{
        const get:any = await instance.post('/api/admin/promotional/create',{
         type:data.type,
         status:data.status,
         url:data.url
        })
        return get.data;
     }catch(e){
        return false;
    }
}
export const getAdminpromotional = async(page?:number,query?:string)=>{
    try{
        let pg = 1;
        if (page) {
          pg = page;
        }
        const get:any = await instance.get(`/api/admin/promotional/get?page=${pg}${query ? `&query=${query}` : ''}`)
        return get.data;
    }catch(e){
        return false;
    }
}
export const showAdminpromotional = async(id:string)=>{
    try{
        const get:any = await instance.get(`/api/admin/promotional/show/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}
export const editAdminpromotional = async(id:string,data:adminPromotionalImageTypes)=>{
    try{
        const get:any = await instance.put(`/api/admin/promotional/edit/${id}`,{
            type:data.type,
            status:data.status,
            url:data.url
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const updateStatusAdminpromotional = async(id:string,status:boolean)=>{
    try{
        const get:any = await instance.put(`/api/admin/promotional/status/${id}`,{
           status:status
        });
        return get.data;
    }catch(e){
            return false;
    }
}
export const deleteAdminpromotional = async(id:string)=>{
    try{
        const get:any = await instance.delete(`/api/admin/promotional/delete/${id}`);
        return get.data;
    }catch(e){
            return false;
    }
}