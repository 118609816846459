import React, { useEffect, useState } from 'react'
import { createVideoRelationByCourse, getAllVideoModules, getAllVideoModulesSubModules, getAllVideoonlySubmodules, getAllVideoRelationByCourse } from '../../../../service/admin/adminVideoes.service';
import MultiSearchableSelect from '../../../../components/form/MultiSearchableSelect';
import { SubmitHandler, useForm } from 'react-hook-form';
import formValidationRules from '../../../../helpers/formvalidationRules';
import FormButton from '../../../../components/button/FormButton';
import { toast } from 'react-toastify';
export default function AdminCourseVideoRelation(props:{id:string}) {

    const [fetchAllList, setFetchList] = useState([]);
    const [fetchSubModuleList, setFetchSubModuleList] = useState<any>([]);
    const [defaultList, setDefaultList] = useState([]);
    const [selectedModules, setSelectedModules] = useState([])
    const [selectedSubModules, setSelectedSubModules] = useState([])
    const [onlySubModules, setOnlySubModules] = useState<any>([])
    const [allOnlySubModules, setAllOnlySubModules] = useState<any>([])
    const  [loading,setLoading] = useState(false);

    const fetchModuleData = async()=>{
        let get:any = await getAllVideoModules();
        if(get?.status==="success"){
            let data:any = [];
            get.message.map((item:any)=>{
              data.push({value:item.id,label:item.name})
            })
            setFetchList(data)
          }
    }

    const fetchSubModuleData = async(id:string)=>{
        let get:any = await getAllVideoModulesSubModules(id)
        if (get?.status === "success") {
          let data: any = [];
          get.message.map((item: any) => {
              data.push({ value: item.id, label: item.name, mod: item.fk_videoModule_id });
          });
  
          // Merge with existing submodules, avoiding duplicates
          const mergedArray = [...fetchSubModuleList, ...data.filter((newItem:any) => 
              !fetchSubModuleList.some((existingItem:any) => existingItem.value === newItem.value)
          )];
  
          setFetchSubModuleList(mergedArray);
      }
    }

    const fetchOnlySubmodules = async()=>{
      let get:any = await getAllVideoonlySubmodules();
      if(get?.status==="success"){
        let data:any = [];
        get.message.map((item:any)=>{
          data.push({value:item.id,label:item.name})
        })
        setAllOnlySubModules(data)
      }
    }

      const getSelectedData = async(id:string)=>{
        let get:any = await getAllVideoRelationByCourse(id)
        if(get?.status === "success"){
          if(get?.message.length > 0){
            let getmod:string[] = []
            let selectedSub = []
            let selesub:any  = [];
            let onlySub:any= []
            get?.message.map((e:any)=>{
              getmod.push(e.fk_videoModule_id)
              selectedSub.push(e.fk_videoSubModule_id)
              if(e.videoModule?.videoSubModule?.length > 0){
                e.videoModule.videoSubModule.map((it:any)=>{
                  selesub.push({
                    label:it.name,
                    value:it.id,
                    mod: e.fk_videoModule_id,
                  })
                })
              }
              if(e.fk_videoModule_id === null){
                onlySub.push(e?.videoSubModule?.id)
              }
            })
            const uniqueArray: any = [...new Set(getmod)];
            setSelectedModules(uniqueArray)
            const uniqueArray1 = selesub.reduce((acc: any, current:any) => {
              const x = acc.find((item:any) => item.value === current.value);
              if (!x) {
                  acc.push(current);
              }
              return acc;
          }, []);
            setOnlySubModules(onlySub)
            setFetchSubModuleList(uniqueArray1)
            setSelectedSubModules(get?.message.map((item:any) => item.fk_videoSubModule_id))
          }
        }
      }
    useEffect(()=>{
        fetchModuleData();
        fetchOnlySubmodules()
        getSelectedData(props.id);
    },[props.id])

    const { handleSubmit, control } = useForm<any>({
      values:{
        modules : selectedModules ? selectedModules : [],
        sub_modules:selectedSubModules ? selectedSubModules : [],
        ony_submodules:onlySubModules ? onlySubModules : [],
      }
    });
  
    const onSubmit: SubmitHandler<any> = async(data:any) => {
      setLoading(true);
      let sendingData:any = [];
      console.log(data.sub_modules)
      if(data.sub_modules.length > 0){
        data.sub_modules.map((e:any) => {
          let filter:any = fetchSubModuleList.filter((i:any) => i.value === e )
          console.log(filter)
          if(filter.length > 0){
            sendingData.push({
              fk_course_id: props.id,
              fk_videoModule_id: filter[0].mod,
              fk_videoSubModule_id: filter[0].value
            })
          }
          
        })
      }
      console.log(data.ony_submodules)
      if(data.ony_submodules.length > 0){
        data.ony_submodules.map((e:any) => {
          sendingData.push({
            fk_course_id: props.id,
            fk_videoModule_id: null,
            fk_videoSubModule_id: e
          })
        })
      }
      let cr =  await createVideoRelationByCourse(sendingData)
      if(cr?.status === 'success'){
        toast.success("Course Video relation added successfully");
       
      }
    setLoading(false)
    
    };  
    const handleModuleChange = async(e:any) => {
      
      //   if(e.removed){
      // let filteredList = fetchSubModuleList.filter((i:any) => i.mod !== e.removed);
      //   setFetchSubModuleList(filteredList);
      //   }
      //   if(e.selected.length === 0){
      //     setFetchSubModuleList([])
      //   }
        
        if(e.selected.length > 0){
          if(e.removed){
            let findExist = fetchAllList.filter((i:any) => i.value === e.removed)
            if(findExist.length > 0){
              let filteredList = fetchSubModuleList.filter((i:any) => i.mod !== e.removed);
              setFetchSubModuleList(filteredList);
            }else{
              fetchSubModuleData(e.selected[e.selected.length - 1]);
            }
           
          }else{
            fetchSubModuleData(e.selected[e.selected.length - 1]);
          }
          
        
        }else{
          setFetchSubModuleList([])
        }

    }
  return (
    <>
     <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
                <div className='mb-[1rem]'>
                <MultiSearchableSelect
                name="modules"
                control={control}
                options={fetchAllList}
                label="Select Module"
                placeholder="Search.." 
                onFetch={handleModuleChange}
                />
                 <MultiSearchableSelect
                name="sub_modules"
                control={control}
                options={fetchSubModuleList}
                rules={formValidationRules.required()}
                label="Select Sub Module"
                placeholder="Search.." 
                />
                <MultiSearchableSelect
                name="ony_submodules"
                control={control}
                options={allOnlySubModules}
                rules={formValidationRules.required()}
                label="Select Only Sub Module"
                placeholder="Search.." 
                />
                <FormButton loading={loading} title={`Submit`} />
                </div>
                
                </form>
    </>
    

  )
}
