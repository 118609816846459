import React from 'react'
import { FaCalendar, FaRupeeSign } from 'react-icons/fa'

export default function SimpleTable(props:{rows:any,cols:any}) {


  return (
    

<div className="relative overflow-x-auto rounded-lg  max-h-[300px] min-h-[100px]">
    <table className="w-full   text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                {
                    props.cols.length > 0 ?
                        props.cols.map((col:any) =>(
                            <th scope="col" className="py-2 px-2">
                               {col}
                            </th>
                        ))
                    :null
                }
            </tr>
        </thead>
        <tbody>
         {
            props.rows.length > 0 ?
                props.rows.map((row:any,index:number) =>(
                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 text-center even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className='px-2 py-2 text-center'>
                            {row[0]}
                        </td>
                        <td className='px-2 py-2 text-center'>
                            <p className='flex flex-row  items-center'>
                                <FaRupeeSign /> {row[1]}
                            </p>
                        </td>
                        <td className='px-2 py-2 text-center'>
                        <p className='flex flex-row  items-center'>
                                <FaRupeeSign /> {row[2]}
                            </p>
                        </td>
                        <td className='px-2 py-2 text-center'>
                        <p className='flex flex-row  items-center'>
                                 {row[3]}
                            </p>
                        </td>
                        <td className='px-2 py-2 text-center'>
                            {row[4]}
                        </td>
                    </tr>
                ))
            :null
         }
        </tbody>
    </table>
</div>

  )
}
